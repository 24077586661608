import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import { WebView } from "react-native-webview";
import {
  ShouldStartLoadRequest,
  WebViewErrorEvent,
  WebViewHttpErrorEvent,
  WebViewMessageEvent,
  WebViewSource,
} from "react-native-webview/lib/WebViewTypes";
import { LogCmd } from "./LogCmd";
import { appName, getBuildNumber } from "../../lib/expo/appConfig";
import { isEmulator } from "../../lib/firebase/ifEnv";
import { WebWaitOverlay } from "./WebWaitOverlay";
import useError from "react-use/lib/useError";

export type WebContainerProps = {
  origin: string;
  title?: string;
  path?: string | null;
  token?: string | null;

  onCanGoBack?: (canGoBack: boolean) => void;
};

const allowedSchemes = ["http", "https"];

// a wrapper around a WebView with just adds functionality to handle the mechanics of an
// embedded WebView
export const WebContainer = React.forwardRef<WebView, WebContainerProps>((props, propsRef) => {
  const dispatchError = useError();
  const [showOverlay, setShowOverlay] = useState(true);
  const webViewRef = useRef<WebView>(null);

  // expose the inner ref to the outside
  useImperativeHandle(propsRef, () => webViewRef.current!, [webViewRef]);

  // check that we navigate only to valid locations
  const shouldLoadRequest = React.useCallback((request: ShouldStartLoadRequest) => {
    console.log(
      `🍏 WEBVIEW load request: ${request.url}`,
      `[main document: ${request.mainDocumentURL}]`,
      `[${request.isTopFrame}]`
    );

    // check that we don't navigate to "about:blank" or anything like that
    // in the main document frame
    if (request.isTopFrame) {
      const reqScheme = request.url.substring(0, request.url.indexOf(":"));
      if (!allowedSchemes.includes(reqScheme)) {
        // .. for now we just throw an error
        dispatchError(new Error("🍎 WEBVIEW unsupported url: " + request.url));
        return false;
      }
    }
    return true;
  }, []);

  // when the page resources are load remove the overlay
  const handleLoadEnd = React.useCallback((event: any) => {
    // remove the overlay with a small delay. looks nicer.
    setTimeout(() => {
      setShowOverlay(false);
    }, 200);
  }, []);

  // some other error
  const handleError = React.useCallback((event: WebViewErrorEvent) => {
    console.log("🍎 WEBVIEW error:", event);
    dispatchError(
      new Error(`🍎 WEBVIEW error: ${event.nativeEvent?.code} + ${event.nativeEvent?.description}`)
    );
    event.preventDefault();
  }, []);

  // HTTP protocol error
  const handleHttpError = React.useCallback((event: WebViewHttpErrorEvent) => {
    console.log("🍎 WEBVIEW http error:", event.nativeEvent.statusCode);
    // TODO: better solution necessary. NextJS would render a nice error page, e.g. "not found".
    //       a hard error from the app would be disruptive.
    //       show a special message or just go back in the app? or just go to the app's home screen?
    // dispatchError(new Error(`🍎 WEBVIEW http error: ${event.nativeEvent.description}`));
    event.preventDefault();
  }, []);

  // we only support logging as a commanf from the web app
  const handleMessage = React.useCallback((event: WebViewMessageEvent) => {
    try {
      console.log("🍏 WEBVIEW msg:", event.nativeEvent.data);
      if (event.nativeEvent.data.length < 50000) {
        const msg: Partial<LogCmd> = JSON.parse(event.nativeEvent.data);

        // "log" is the only cmd we handle for now
        if (msg.cmd === "log") {
          if (msg.params?.level === "error") {
            console.log("🍊 EMBEDED WEB ERROR:", msg.params?.message);
          } else {
            console.log("🍏 EMBEDED WEB LOG:", msg.params?.message);
          }
        }
      } else {
        console.error("🍎 WEBVIEW MSG: too long", event.nativeEvent.data);
      }
    } catch (error) {
      console.error("🍎 WEBVIEW MSG: error processing message", event?.nativeEvent?.data, error);
    }
  }, []);

  // if the OS terminates the render process, we just reload. this probably happens
  // when the app is longer in the background.
  const handleTerminate = React.useCallback(() => {
    // NOTE: an alternative would be to notify the parent and navigate out of the web view.
    webViewRef.current?.reload();
  }, [webViewRef]);

  let emuWhiteList: string[] = [];
  if (isEmulator()) {
    emuWhiteList = [
      // ios
      "http://localhost:19006", // metro
      "http://localhost:9099", // auth
      "http://localhost:5001", // functions
      "http://localhost:5002", // hosting
      "http://localhost:8888", // firestore
      // android
      "http://10.0.2.2:19006", // metro
      "http://10.0.2.2:9099", // auth
      "http://10.0.2.2:5001", // functions
      "http://10.0.2.2:5002", // hosting
      "http://10.0.2.2:8888", // firestore
    ];
  }

  // the unique user agent can be used by the web app to detect the host app
  const userAgent = `${appName()} BackOffice/${getBuildNumber()}`;

  const provideConstantsScript = `
    if( !window.Recirclable ) window.Recirclable = {};
    window.Recirclable.token="${props.token ?? null}";
    true;
  `;

  // the actual uri loaded
  const source: WebViewSource = useMemo(
    () => ({ uri: !!props.path ? props.origin + props.path : props.origin }),
    [props.origin, props.path]
  );

  return (
    <>
      <WebView
        ref={webViewRef}
        style={{ flex: 1, backgroundColor: "#f0f4f0" }}
        /* -- general -- */
        autoManageStatusBarEnabled={false}
        applicationNameForUserAgent={userAgent}
        setBuiltInZoomControls={false}
        geolocationEnabled={true}
        cacheEnabled={true}
        // enableApplePay={true} ... Apple Pay still works. Hmmm?
        /* -- cookie -- */
        sharedCookiesEnabled={false} // no auth from outside!
        domStorageEnabled={true} // Android only
        /* -- rendering related -- */
        contentMode="mobile"
        allowsLinkPreview={false}
        textZoom={100}
        // textInteractionEnabled={false}
        /* -- scrolling related -- */
        overScrollMode="never"
        scrollEnabled={true}
        pullToRefreshEnabled={true}
        /* -- video related -- */
        allowsFullscreenVideo={false}
        allowsInlineMediaPlayback={true}
        mediaPlaybackRequiresUserAction={false}
        mediaCapturePermissionGrantType={"grant"}
        /* -- content source -- */
        source={source}
        originWhitelist={["*"]}
        // TODO: a proper white list must contain all domain we need and all the domains for
        // square auth and other web sites we show.
        //
        // originWhitelist={[
        //   props.rootUri,
        //   "https://*.recirclable.app",
        //   "https://*.firebaseapp.com",
        //   "https://*.stripe.com",
        //   "https://*.stripe.network",
        //   ...emuWhiteList,
        // ]}
        /* -- auth token -- */
        injectedJavaScriptBeforeContentLoaded={provideConstantsScript}
        /* -- event handler -- */
        onNavigationStateChange={(event) => {
          // sufficient on iOS to figure out whether we can go back
          props.onCanGoBack?.(event.canGoBack);
        }}
        onLoadProgress={(event) => {
          // necessary on Android to figure out whether we can go back (more than once fired)
          props.onCanGoBack?.(event.nativeEvent.canGoBack);
        }}
        onLoadEnd={handleLoadEnd}
        onMessage={handleMessage}
        onShouldStartLoadWithRequest={shouldLoadRequest}
        onContentProcessDidTerminate={handleTerminate}
        onRenderProcessGone={handleTerminate}
        // onNavigationStateChange={(event) => {
        //   console.log("DEBUG WEBVEW NAV STATE", event.url);
        //   return true;
        // }}
        onError={handleError}
        onHttpError={handleHttpError}
      />
      {showOverlay ? <WebWaitOverlay label="Loading dashboard ..." /> : null}
    </>
  );
});

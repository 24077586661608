import React, { useCallback, useRef, useState } from "react";
import { CodeResultSuccess } from "../../../../util/code";
import { ContainerDoc, Containers } from "../../../../model/ContainerDoc";
import { QRCodeScanner, ScanMsg } from "../../../../components/camera/QRCodeScanner";
import { docGet } from "../../../../lib/firestore/fstore";
import { CameraView } from "expo-camera";

export const ContainerScanner: React.FunctionComponent<{
  disabled?: boolean;
  tempIgnoreCode?: string;
  alreadyScanned: ContainerDoc[];
  validate?: (container: ContainerDoc) => string | null;
  onAddContainer: (container: ContainerDoc) => void;
  onNoCamera: () => void;
  cameraRef?: React.MutableRefObject<CameraView | null>;
}> = (props) => {
  const [msg, setMsg] = useState<ScanMsg | null>(null);
  const [items, setItems] = useState<ContainerDoc[]>([]);
  const countRef = useRef(0);

  // BUG ON WEB:
  // The expo-camera web implementations (and not the native) holds on to the first onScan() passed in
  // and never updates it. This also means that the onScan() function here never "sees" the update
  // props.alreadyScanned. It would be possible to implement a workaround. We we aren't doing it. It
  // only influences the "alreadyScanned" check and nothing else.
  const onScan = useCallback(
    (result: CodeResultSuccess) => {
      if (!props.disabled) {
        const scanned = props.alreadyScanned.find((scanned) => scanned.id === result.code);
        if (!scanned) {
          docGet(Containers.doc(result.code)).then((container) => {
            if (container) {
              const error = props.validate?.(container);
              if (!error) {
                setItems((s) => s.concat([container]));
                props.onAddContainer(container);
                setMsg({ type: "success", text: `Container '${container.name}' scanned` });
              } else {
                setMsg({ type: "error", text: error });
              }
            } else {
              setMsg({ type: "error", text: "Unknown container code" });
            }
          });
        } else {
          setMsg({ type: "warn", text: "Container already scanned" });
        }
      }
    },
    [
      items,
      props.alreadyScanned,
      props.alreadyScanned.length,
      props.disabled,
      props.tempIgnoreCode,
      props.onAddContainer,
      props.validate,
    ]
  );

  return (
    <QRCodeScanner
      tempIgnoreCode={props.tempIgnoreCode}
      expectedTypes={["item"]}
      onScan={countRef.current < 2 ? onScan : undefined}
      onNoCamera={props.onNoCamera}
      overlayCaption="Scan Container"
      msg={msg}
      cameraRef={props.cameraRef}
    />
  );
};

import React from "react";
import { Radio, Text } from "native-base";

export const SelfReturnScanIssueModalBody = (props: {
  initialValue: string;
  onValueChange: (value: string) => void;
}) => {
  return (
    <>
      <Text my={2}>Please describe the issue:</Text>
      <Radio.Group
        name="ReasonCode"
        colorScheme="gray"
        value={props.initialValue ?? ""}
        onChange={(nextValue) => {
          props.onValueChange(nextValue);
        }}
      >
        <Radio value="label_damaged" my={1}>
          QR code label damaged
        </Radio>
        <Radio value="no_label" my={1}>
          Missing QR code label
        </Radio>
        <Radio value="dont_know_what_to_do" my={1}>
          Don't know what to scan
        </Radio>
        <Radio value="camera_not_scanning" my={1}>
          Camera not scanning code
        </Radio>
        <Radio value="other" my={1}>
          Other
        </Radio>
      </Radio.Group>
    </>
  );
};

import {
  Box,
  IBoxProps,
  IconButton,
  Image,
  Pressable,
  ScrollView,
  Skeleton,
  Text,
} from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import {
  GestureResponderEvent,
  ImageSourcePropType,
  Linking,
  AppState,
  Platform,
} from "react-native";
import { appConfigExtra } from "../../../lib/expo/appConfig";
import { isProduction } from "../../../lib/firebase/ifEnv";
import { AssetView } from "../../../components/primitive/AssetView";
import { SectionTextBox } from "../../../components/layout/SectionTextBox";
import * as contentful from "contentful";
import { CfLocationFieldsType } from "../../../../../backend-types/src";
import { expandProtoRelUrl } from "../../../util/url";
import { ONE_MIN, ONE_SEC } from "../../../util/constants";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { VLocations } from "../../../model/VLocationDoc";
import _debounce from "lodash/debounce";
import { EyeOffIcon, GlobeIcon, PhoneIcon } from "../../../components/icons/Icons";
import { FrontRpc } from "../../../lib/functions/rpc";
import { useRoles } from "../../../lib/auth/roleState";
import useMountedState from "react-use/lib/useMountedState";
import useAsync from "react-use/lib/useAsync";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { docQueryFirst } from "../../../lib/firestore/fstore";
import useToggle from "react-use/lib/useToggle";
import { HFlex } from "../../../components/layout/HFlex";
import { cleanTel } from "../../../util/phone";
import { VFlex } from "../../../components/layout/VFlex";

const TILE_WIDTH = "254px";
const TILE_HEIGHT = "234px";
const TILE_IMAGE_HEIGHT = "128px";

const PartnerTitleBox: React.FunctionComponent<IBoxProps> = (props) => {
  return (
    <VFlex
      position={"relative"}
      mx={2}
      bg="white"
      borderRadius="md"
      overflow="hidden"
      borderColor="gray.200"
      borderWidth={1}
      w={TILE_WIDTH}
      h={TILE_HEIGHT}
      {...props}
    >
      {props.children}
    </VFlex>
  );
};

const PartnerButton: React.FC<{
  phone?: string;
  webUrl?: string;
  onOnlineOrder?: (locationId: string) => void;
  contentfulId: string;
}> = (props) => {
  const handleOnlineOrder = useCallback(() => {
    docQueryFirst(VLocations.query().where("external.contentfulId", "==", props.contentfulId)).then(
      (location) => {
        if (location) props.onOnlineOrder?.(location.id);
      }
    );
  }, [props.contentfulId, props.onOnlineOrder]);

  return (
    <HFlex mx={3} my={2} justifyContent={"space-between"}>
      <HFlex>
        <IconButton
          icon={<GlobeIcon size={"xl"} color={"white"} />}
          padding={0.5}
          backgroundColor={"darkBlue.600"}
          borderRadius={"lg"}
          onPress={() => {
            if (props.webUrl) Linking.openURL(props.webUrl);
          }}
        />
        {props.phone && (
          <IconButton
            icon={<PhoneIcon size={"xl"} color="white" />}
            padding={0.5}
            backgroundColor={"darkBlue.600"}
            borderRadius={"lg"}
            ml={1}
            onPress={() => Linking.openURL(`tel:${props.phone}`)}
          />
        )}
      </HFlex>
      {props.onOnlineOrder && (
        <PrimaryButton
          label="Order"
          px={3}
          py={0}
          borderRadius="lg"
          _text={{ color: "white", fontWeight: "700" }}
          onPress={handleOnlineOrder}
        />
      )}
    </HFlex>
  );
};

const PartnerAddress: React.FunctionComponent<{
  name: string;
  address1: string;
  address2: string;
}> = (props) => {
  return (
    <VFlex my={0} mx={3.5}>
      <Text lineHeight="xs" fontSize="sm" fontWeight="700">
        {props.name}
      </Text>
      <Text fontSize="xs" fontWeight="400">
        {props.address1}
      </Text>
      <Text fontSize="xs" fontWeight="400" lineHeight={"xs"}>
        {props.address2}
      </Text>
    </VFlex>
  );
};

const PartnerTile: React.FunctionComponent<{
  visible?: boolean;
  name: string;
  address1: string;
  address2: string;
  imageSource: ImageSourcePropType;
  imageBackgroundColor?: string;
  imageResizeMode?: "cover" | "contain";
  url?: string;
  phone?: string;
  contentfulId: string;
  onOnlineOrder?: (locationId: string) => void;
}> = (props) => {
  return (
    <PartnerTitleBox>
      <Image
        borderBottomWidth={1}
        borderColor="gray.100"
        source={props.imageSource}
        alt={props.name}
        backgroundColor={props.imageBackgroundColor ?? "white"}
        resizeMode={props.imageResizeMode ?? "cover"}
        w="100%"
        h={TILE_IMAGE_HEIGHT}
      />
      {props.visible === false && (
        <Box position="absolute" top={2} left={2}>
          <EyeOffIcon color="primaryText" size="lg" />
        </Box>
      )}
      <PartnerButton
        phone={props.phone}
        onOnlineOrder={props.onOnlineOrder}
        webUrl={props.url}
        contentfulId={props.contentfulId}
      />
      <PartnerAddress name={props.name} address1={props.address1} address2={props.address2} />
    </PartnerTitleBox>
  );
};

const SuggestTile: React.FunctionComponent<{ onPress?: () => void }> = (props) => {
  function handlePress(event: GestureResponderEvent) {
    event.preventDefault();
    if (props.onPress) props.onPress();
  }

  return (
    <PartnerTitleBox>
      <AssetView
        position="absolute"
        source={require("assets/images/suggest-background.svg")}
        alt="Background Illustration"
        w={"110%"}
        h={"110%"}
      />
      <VFlex alignItems="center" justifyContent="space-evenly" h="100%" py={2}>
        <Text color="primaryText" fontSize="md" fontWeight="700">
          Recommend a business
        </Text>
        <Pressable onPress={handlePress}>
          <AssetView
            source={require("assets/illustrations/Chef.svg")}
            alt="Recommend a business"
            resizeMode="contain"
            w={"160px"}
            h={"160px"}
          />
        </Pressable>
        <Text color="primaryText" fontSize="md" fontWeight="700">
          to Recirclable!
        </Text>
      </VFlex>
    </PartnerTitleBox>
  );
};

function PartnerTileList(props: {
  cfLocations: contentful.Entry<CfLocationFieldsType>[];
  onOnlineOrder?: (locationId: string) => void;
}) {
  const tiles = props.cfLocations.map((location) => (
    <PartnerTile
      key={location.sys.id}
      visible={location.fields.visible}
      name={location.fields.name}
      address1={`${location.fields.address1} ${location.fields.address2 ?? ""}`}
      address2={`${location.fields.city} ${location.fields.state} ${location.fields.zipCode}`}
      imageSource={
        location.fields.image?.fields.file.url
          ? { uri: expandProtoRelUrl(location.fields.image?.fields.file.url) }
          : require("assets/illustrations/partner-placeholder.png")
      }
      imageResizeMode={location.fields.resizeMode === "contain" ? "contain" : "cover"}
      imageBackgroundColor={location.fields.backgroundColor}
      url={location.fields.url}
      phone={cleanTel(location.fields.phone)}
      contentfulId={location.sys.id}
      onOnlineOrder={
        location.fields.onlineOrder && location.fields.onlineOrderUrl
          ? Platform.select({ native: props.onOnlineOrder })
          : undefined
      }
    />
  ));

  return <>{tiles}</>;
}

function SkeletonTile() {
  return (
    <PartnerTitleBox>
      <Skeleton w="100%" h={TILE_IMAGE_HEIGHT} />
      <Box m={0} p={0} borderTopWidth={1} borderColor="gray.100">
        <VFlex my={2} mx={4}>
          <Skeleton my={2} w="150px" h="14px" />
          <Skeleton my={1} w="70px" h="10px" />
          <Skeleton my={1} w="90px" h="10px" />
        </VFlex>
      </Box>
    </PartnerTitleBox>
  );
}

function SkeletonTileList() {
  return (
    <>
      <SkeletonTile />
      <SkeletonTile />
    </>
  );
}
// coordinate of 8 Wollaston Ave, Arlington MA 02116: latitude 42.42, longitude -71.18
const DEFAULT_LOCATION = "42.42,-71.18";
export const PartnerSection: React.FunctionComponent<{
  title?: string;
  onlyOnlineOrder?: boolean;
  suggestTile?: boolean;
  onSuggest?: () => void;
  onOnlineOrder?: (locationId: string) => void;
}> = (props) => {
  const { isAdmin } = useRoles();
  const isMounted = useMountedState();
  const [geoLoc, setGeoLoc] = useState<string>("");
  const [showAll, toggleShowAll] = useToggle(false);

  const debounceTime = isProduction() ? 5 * ONE_MIN : 10 * ONE_SEC;
  const [geoState, queryGeo] = useAsyncFn(
    _debounce(
      async () => {
        try {
          let location = await FrontRpc.call("geoLocation", {});
          if (location.cityLat && location.cityLong) {
            if (isMounted()) setGeoLoc(`${location.cityLat},${location.cityLong}`);
          } else {
            if (isMounted()) setGeoLoc(DEFAULT_LOCATION);
          }
        } catch {
          if (isMounted()) setGeoLoc(DEFAULT_LOCATION);
        }
      },
      debounceTime,
      { leading: true }
    )
  );

  useEffect(() => {
    queryGeo();
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (nextAppState == "active") {
        queryGeo();
      }
    });
    return () => {
      subscription?.remove();
    };
  }, [queryGeo]);

  // Contentful comment regarding accurcay of a location search:
  // Queries that include exact coordinates can't take advantage of our caching layer. With
  // many use cases it should be enough to round the coordinates to 3 decimal places(an accuracy
  // of about 300m), 2 decimal places(an accuracy of about 1km) or more to improve your cache
  // hit rates.

  const locationState = useAsync(async () => {
    if (geoLoc) {
      return await getContentfulEntries(isProduction(), geoLoc, showAll);
    }
    return null;
  }, [geoLoc, showAll]);

  const getContentfulEntries = async (noTest: boolean, coordinate: string, allVisible: boolean) => {
    const contentfulClient = contentful.createClient({
      space: appConfigExtra().contentful.spaceId,
      accessToken: appConfigExtra().contentful.accessToken,
    });

    // different filter conditions
    const testFilter = noTest ? { "fields.test[ne]": true } : {};
    const orderFilter = props.onlyOnlineOrder ? { "fields.onlineOrder": "toast" } : {};
    const visibleFilter = isAdmin && allVisible ? undefined : { "fields.visible": true };

    const { items, ..._ } = await contentfulClient.getEntries<CfLocationFieldsType>({
      content_type: "location",
      "fields.geoLocation[near]": coordinate,
      limit: 20,
      ...visibleFilter,
      ...testFilter,
      ...orderFilter,
    });
    return items;
  };

  return (
    <VFlex w="100%">
      <Pressable onLongPress={toggleShowAll}>
        <SectionTextBox mt={3} mb={1}>
          <HFlex alignItems="center">
            <Text fontSize="md" fontWeight="700" mr={2} selectable={false}>
              {props.title ?? "Check out these Recirclable restaurants"}
            </Text>
            {isAdmin && showAll && <EyeOffIcon color="primaryText" size="lg" />}
          </HFlex>
        </SectionTextBox>
      </Pressable>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        width="100%"
        maxHeight={TILE_HEIGHT}
      >
        <HFlex>
          <Box mx={1} w="1px" />
          {!!locationState.value ? (
            <PartnerTileList
              cfLocations={locationState.value}
              onOnlineOrder={props.onOnlineOrder}
            />
          ) : (
            <SkeletonTileList />
          )}
          {!!props.suggestTile && <SuggestTile onPress={props.onSuggest} />}
          <Box mx={1} w="1px" />
        </HFlex>
      </ScrollView>
    </VFlex>
  );
};

import { Pressable, Text } from "native-base";
import React, { useRef } from "react";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { QRCodeScanner } from "../../../components/camera/QRCodeScanner";
import { AssetView } from "../../../components/primitive/AssetView";
import { HFlex } from "../../../components/layout/HFlex";
import { VSpace } from "../../../components/layout/VSpace";
import { FullScreen } from "../../../components/layout/FullScreen";
import { CodeResult } from "../../../util/code";
import { ifNotProduction } from "../../../lib/firebase/ifEnv";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { VPoints } from "../../../model/VPointDoc";
import { selectPointScreen } from "./selectPointScreen";
import { docGet } from "../../../lib/firestore/fstore";
import { VFlex } from "../../../components/layout/VFlex";

export const PointScanScreen = ({ navigation, route }: FrontAppScreenProps<"Scan">) => {
  const procesingCode = useRef(false);
  const closeScreen = useCloseScreen();

  function handleScan(result: CodeResult) {
    // only one code is processed at a time
    if (!procesingCode.current) {
      procesingCode.current = true;

      switch (result.type) {
        case "point":
          docGet(VPoints.doc(result.code!))
            .then((point) => {
              if (point) {
                const screen = selectPointScreen(point);
                navigation.replace(screen, { pointId: point.id, locationId: point.locationId });
                // IMPORTANT: keep processingCode = true
              } else {
                procesingCode.current = false;
              }
            })
            .catch((error) => {
              procesingCode.current = false;
            });
          break;

        case "item":
          navigation.replace("ItemInfo");
          // IMPORTANT: keep processingCode = true
          break;
      }
    }
    return undefined;
  }

  return (
    <FullScreen name="Borrow & Return Scan">
      <QRCodeScanner
        expectedTypes={["point", "item"]}
        onScan={handleScan}
        onNoCamera={closeScreen}
        overlayCaption="Scan Signage"
      />
      <HFlex px={2} mx={4} my={8} justifyContent="flex-start" alignItems="center">
        <AssetView
          source={require("assets/illustrations/QR-Scan.svg")}
          alt="Illustration Welcome 1"
          resizeMode="contain"
          w={160}
          h={160}
        />
        <VFlex flex={1} w="80%" px={2}>
          <Pressable
            onLongPress={ifNotProduction(() =>
              handleScan({ type: "point", code: "sb123456789012345678" })
            )}
          >
            <Text>
              To <Text fontFamily={"NotoSans-Bold"}> BORROW </Text>
              <Text bold> scan the QR code on the signage at the </Text>
              <Text fontFamily={"NotoSans-Bold"}>counter</Text>.
            </Text>
          </Pressable>
          <VSpace h={4} />
          <Pressable
            onLongPress={ifNotProduction(() =>
              handleScan({ type: "point", code: "sr123456789012345678" })
            )}
          >
            <Text>
              To <Text fontFamily={"NotoSans-Bold"}> RETURN </Text>
              <Text bold> scan the QR code on the signage at the </Text>
              <Text fontFamily={"NotoSans-Bold"}>return bin</Text>.
            </Text>
          </Pressable>
        </VFlex>
      </HFlex>
    </FullScreen>
  );
};

import React, { useCallback, useState } from "react";
import { SectionCard } from "../../../components/layout/Sections";
import { Box, Input, Radio } from "native-base";
import { useCustomer } from "../../../model/useCustomer";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { VersionSection } from "../../../components/footer/VersionSection";
import * as WebBrowser from "expo-web-browser";
import { authSignOut, useAuthState } from "../../../lib/auth/authState";
import { BackOfficeHeaderButtons } from "../../../components/navigation/BackOfficeHeaderButtons";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import {
  CardIcon,
  EmailIcon,
  KeyIcon,
  LogoAppleIcon,
  LogoGoogleIcon,
  PhoneIcon,
} from "../../../components/icons/Icons";
import { FrontRpc } from "../../../lib/functions/rpc";
import { useMailComposer } from "../../../hooks/useMailComposer";
import { SectionItemChevronButton } from "../../../components/layout/SectionItemChevronButton";
import { ChangeProfileOptions } from "../../../lib/apiDefs";
import { useAlerts } from "../../../components/modals/AlertProvider";
import { VSpace } from "../../../components/layout/VSpace";
import { SectionSecondaryButton } from "../../../components/layout/SectionSecondaryButton";

export const ProfileScreen = ({ navigation, route }: FrontAppScreenProps<"Profile">) => {
  const composeMail = useMailComposer();
  const alerts = useAlerts();
  const { balance, profile } = useCustomer();
  const [name, setName] = useState<string>(profile.name ?? "");
  const [nameFocus, setNameFocus] = useState<boolean>(false);
  const [notification, setNotification] = useState<"phone" | "email">(profile.notification.method);

  const openBrowserInApp = async (url: string) => {
    await WebBrowser.openBrowserAsync(url, {
      windowName: "_self",
      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
    });
  };

  const [_changeState, callChangeProfile] = useAsyncFn(async (options: ChangeProfileOptions) => {
    const result = await FrontRpc.call("changeProfile", options);
    return result;
  });

  function handleNameInputChange(text: string) {
    setName(text);
  }

  const handleSubmitNameChange = useCallback(async () => {
    await callChangeProfile({ name });
  }, [name]);

  function handleSubmitNotificationChange(value: string) {
    if (value === "email" || value === "phone") {
      setNotification(value);
      callChangeProfile({ notification: value });
    }
  }

  async function handleSupport() {
    composeMail({
      to: "hello@recirclable.com",
      subject: `Support Question [${balance.id.substring(0, 6)}]`,
      body: "Please write us an email with your support question. Thank you!",
    });
  }

  async function handleSignOut() {
    await authSignOut();
    // nothing else to do. MainNavigation will swap out the screens.
  }

  function handleDelete() {
    // only allow deleting the account, if there is no outstanding balance
    if (balance.outstandingSum) {
      const bowl = balance.outstandingSum > 1 ? "bowls" : "bowl";
      const message = `You still have ${balance.outstandingSum} ${bowl} borrowed. We would love for you to stay with Recirclable, but if you must leave, please return all bowls before deleting your account.`;
      alerts.openAlertOk({
        title: "Delete Account",
        body: message,
      });
    } else {
      navigation.push("DeleteAccount");
    }
  }

  return (
    <Screen name="Profile" right={<BackOfficeHeaderButtons />}>
      <SectionCard title="Name">
        <Input
          nativeID="name"
          flex={1}
          fontSize="md"
          placeholder={nameFocus ? "" : "Add First & Last Name"}
          placeholderTextColor="primaryText"
          returnKeyType="done"
          autoComplete="name"
          autoCorrect={false}
          value={name}
          onChangeText={handleNameInputChange}
          onFocus={() => setNameFocus(true)}
          onBlur={handleSubmitNameChange}
        />
      </SectionCard>

      <SectionCard title="Contact" withDivider={true}>
        <ChangeEmailButton
          email={profile.email}
          onPress={() => navigation.push("ProfileChangeEmail")}
        />
        <ChangePhoneButton
          phone={profile.phone}
          phoneVerified={profile.phoneVerified}
          onPress={() => navigation.push("ProfileChangePhone")}
        />
      </SectionCard>
      <SectionCard title="Notification">
        <ChangeNotificationGroup
          email={profile.email}
          phone={profile.phone}
          notification={notification}
          onChange={handleSubmitNotificationChange}
        />
      </SectionCard>

      <SectionCard title="Payment">
        <SectionItemChevronButton
          label={profile?.paymentInfo ?? ""}
          iconLeft={<CardIcon />}
          onPress={() => navigation.push("PaymentChange")}
        />
      </SectionCard>

      <SectionCard title="Need some help?" withDivider={true}>
        <SectionItemChevronButton label="Support" onPress={handleSupport} />
        <SectionItemChevronButton
          label="FAQ"
          onPress={() => openBrowserInApp("https://www.recirclable.com/faq")}
        />
        <SectionItemChevronButton
          label="About"
          onPress={() => openBrowserInApp("https://www.recirclable.com")}
        />
      </SectionCard>
      <VSpace h={16} />
      <SectionSecondaryButton my={1} onPress={handleSignOut} label="Sign Out" />
      <SectionSecondaryButton my={1} onPress={handleDelete} label="Delete Account" />

      <VersionSection />
    </Screen>
  );
};

function ChangePhoneButton(props: {
  phone?: string | null;
  phoneVerified?: boolean;
  onPress: () => void;
}) {
  const { signInProviderId, isPhoneSignIn } = useAuthState((s) => ({
    signInProviderId: s.signInProviderId,
    isPhoneSignIn: s.isPhoneSignIn,
  }));

  const isDisabled = !signInProviderId || isPhoneSignIn;

  let phoneChangeText;
  if (props.phone) {
    phoneChangeText = props.phone;
  } else {
    phoneChangeText = "Add Phone Number";
  }

  const needsVerification = props.phone && !props.phoneVerified;

  return (
    <SectionItemChevronButton
      label={phoneChangeText}
      tag={needsVerification ? "Please verify" : undefined}
      tagColor="danger.700"
      isDisabled={isDisabled}
      iconLeft={<PhoneIcon />}
      iconRight={isPhoneSignIn ? <KeyIcon /> : undefined}
      onPress={props.onPress}
    />
  );
}

function ChangeEmailButton(props: { email?: string | null; onPress: () => void }) {
  const { signInProviderId, isEmailSignIn, hasGoogleProvider, hasAppleProvider } = useAuthState(
    (s) => ({
      signInProviderId: s.signInProviderId,
      isEmailSignIn: s.isEmailSignIn,
      hasGoogleProvider: s.hasGoogleProvider,
      hasAppleProvider: s.hasAppleProvider,
    })
  );
  const isDisabled = !signInProviderId || isEmailSignIn || hasGoogleProvider || hasGoogleProvider;

  const label = props.email ?? "Add Email Address";
  let iconLeft = <EmailIcon />;
  if (hasGoogleProvider) {
    iconLeft = <LogoGoogleIcon />;
  } else if (hasAppleProvider) {
    iconLeft = <LogoAppleIcon />;
  }

  return (
    <SectionItemChevronButton
      label={label}
      isDisabled={isDisabled}
      iconLeft={iconLeft}
      iconRight={isEmailSignIn ? <KeyIcon /> : undefined}
      onPress={props.onPress}
    />
  );
}

function ChangeNotificationGroup(props: {
  notification?: string;
  email?: string | null;
  phone?: string | null;
  onChange: (value: string) => void;
}) {
  return (
    <Radio.Group
      name="notificationMethod"
      accessibilityLabel="Select Notification Method"
      colorScheme="blueGray"
      value={props.notification}
      onChange={props.onChange}
    >
      <Box p={2} px={3} w="100%">
        <Radio
          value="email"
          size="sm"
          _text={{ color: "primaryText" }}
          _disabled={{
            _text: {
              color: "gray.600",
            },
          }}
          isDisabled={!props.email}
          justifyContent="flex-start"
        >
          Notify with Email
        </Radio>
      </Box>
      <Box p={2} px={3} w="100%">
        <Radio
          value="phone"
          size="sm"
          _text={{ color: "primaryText" }}
          _disabled={{
            _text: {
              color: "gray.600",
            },
          }}
          isDisabled={!props.phone}
          justifyContent="flex-start"
        >
          Notify with Text Message
        </Radio>
      </Box>
    </Radio.Group>
  );
}

import { Text } from "native-base";
import React from "react";
import { SectionTextBox } from "./SectionTextBox";

export const ScreenTitle: React.FunctionComponent<{
  title: string;
}> = (props) => {
  return (
    <SectionTextBox mt={5} mb={2}>
      <Text mt={-1} fontSize="lg" fontWeight="700">
        {props.title}
      </Text>
    </SectionTextBox>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { SectionBox } from "../../../../components/layout/Sections";
import { Box, Heading, Text } from "native-base";
import { BannerImage } from "../../../../components/layout/BannerImage";
import { formatPrice } from "../../../../util/format";
import { useCustomer } from "../../../../model/useCustomer";
import { PaymentBodyContainer } from "./PaymentBodyContainer";
import PaymentInput from "./PaymentInput";

export const PaymentBody: React.FunctionComponent<{
  setup: boolean;
  submitLabel: string;
  onDone?: () => void;
}> = (props) => {
  const { profile, balance } = useCustomer();
  const [isProcessing, setProcessing] = useState(false);
  const [isDone, setDone] = useState(false);

  // send the "done" when we also see the profile updated
  useEffect(() => {
    if (isDone && profile.hasPayment()) {
      props.onDone?.();
    }
  }, [isDone, profile.hasPayment()]);

  const handleProcessing = useCallback((state: boolean) => {
    setProcessing(state);
  }, []);

  // done might be delayed until we see the profile state change
  const handleFinished = useCallback(() => {
    if (profile.requiresPaymentSetup()) {
      setDone(true);
    } else {
      props.onDone?.();
    }
  }, []);

  return (
    <PaymentBodyContainer>
      <BannerImage image={require("assets/illustrations/Credit-Card.svg")} />
      <SectionBox>
        <Heading pt={2} fontSize="lg" fontWeight={700} textAlign="center">
          Payment Information
        </Heading>
        <Text mt={1} mx={2} mb={1} textAlign="center">
          {/*
          HACK: the hard coded price and days is necessary because the balance view might not be
          replicated completely. The plan comes from the account. This error became obvious with a
          sluggish wifi network.
          */}
          You will be not charged as long as you return the bowls within {balance?.plan?.days ?? 14}{" "}
          days. After that we charge {formatPrice(balance?.plan?.itemPrice ?? 1500)} for each
          unreturned bowl.
        </Text>
      </SectionBox>
      <SectionBox>
        <PaymentInput
          isDisabled={isProcessing}
          isLoading={isProcessing || (isDone && !profile.hasPayment())}
          setup={props.setup}
          itemPrice={balance?.plan?.itemPrice} // <- might come delayed, because of view replication
          submitLabel={props.submitLabel}
          onProcessing={handleProcessing}
          onDone={handleFinished}
        />
      </SectionBox>
    </PaymentBodyContainer>
  );
};

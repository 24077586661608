import { Heading, KeyboardAvoidingView, Text } from "native-base";
import { Screen } from "../../../components/layout/Screen";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { useCallback, useState } from "react";
import { PhoneSetupScreenProps } from "./phoneSetupStack";
import { PhoneChangeBody } from "../../main/profile/PhoneChangeBody";
import { authSignOut } from "../../../lib/auth/authState";
import { BannerImage } from "../../../components/layout/BannerImage";
import { SectionBox } from "../../../components/layout/Sections";
import { Platform } from "react-native";

export function PhoneSetupScreen({ navigation, route }: PhoneSetupScreenProps<"PhoneSetup">) {
  const closeScreen = useCloseScreen();
  const [phoneInput, setPhoneInput] = useState("");
  const [codeRequested, setCodeRequested] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const handlePhoneInput = useCallback((phone: string) => {
    setPhoneInput(phone);
    setCodeRequested(true);
  }, []);

  const handleBack = useCallback(async () => {
    if (codeRequested) {
      setCodeRequested(false);
    } else {
      await authSignOut();
      closeScreen();
    }
  }, [codeRequested]);

  const handleSuccess = useCallback(() => {
    /* we actually can't do anything here. the app must swap out the navigation. just disable the UI. */
    setDisabled(true);
  }, []);

  return (
    <Screen name="Account Phone Number" onBack={handleBack}>
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.select({ ios: 80, android: 80 })}
      >
        <BannerImage image={require("assets/illustrations/User-Happy-Phone.svg")} />
        <SectionBox>
          <Heading pt={2} fontSize="lg" fontWeight={700} textAlign="center">
            Complete Your Profile
          </Heading>
          <Text mt={1} mx={2} mb={1} textAlign="center">
            {codeRequested
              ? `Nearly done! A verification code was sent to ${phoneInput}. Please enter that code.`
              : "Please provide your name and a mobile phone number. Thank you!"}
          </Text>
        </SectionBox>
        <PhoneChangeBody
          isDisabled={isDisabled}
          requestName={true}
          codeRequested={codeRequested}
          onInput={handlePhoneInput}
          onSuccess={handleSuccess}
        />
      </KeyboardAvoidingView>
    </Screen>
  );
}

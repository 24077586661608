import React from "react";
import { AlertDialog, Text } from "native-base";
import { PrimaryButton } from "../primitive/PrimaryButton";
import { AssetView } from "../primitive/AssetView";
import { VFlex } from "../layout/VFlex";
import { ImageSourcePropType } from "react-native";

export const AlertOk: React.FunctionComponent<{
  title?: string;
  body?: string | (() => JSX.Element);
  inputBody?: (props: { initialValue: any; onValueChange: (value: any) => void }) => JSX.Element;
  imageSource?: ImageSourcePropType;
  primary?: string;
  isOpen: boolean;
  initialValue?: any;
  onValueChange?: (value: any) => void;
  onClose?: (yes?: boolean) => void;
}> = (props) => {
  const leastDestructiveRef = React.useRef(null);

  function renderBody() {
    if (typeof props.body === "string") {
      return <Text>{props.body}</Text>;
    } else if (typeof props.body === "function") {
      return props.body();
    } else if (props.inputBody && props.onValueChange) {
      return props.inputBody({
        initialValue: props.initialValue,
        onValueChange: props.onValueChange,
      });
    }
    return null;
  }

  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      size="xl"
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        {(props.title || props.imageSource) && (
          <AlertDialog.Header textAlign="center" fontWeight="700">
            {props.title}
            {!!props.imageSource && (
              <VFlex alignItems="center">
                <AssetView source={props.imageSource} alt="Reminder" w="160px" h="160px" />
              </VFlex>
            )}
          </AlertDialog.Header>
        )}
        <AlertDialog.Body px={6} py={4}>
          {renderBody()}
        </AlertDialog.Body>
        <AlertDialog.Footer justifyContent="center">
          <PrimaryButton
            onPress={() => props.onClose?.(true)}
            label={props.primary ?? "Ok"}
            minW="30%"
          />
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

import omit from "lodash/omit";
import { Divider, IBoxProps, Text, VStack } from "native-base";
import React from "react";
import { ImageSourcePropType } from "react-native";
import { AssetView } from "../primitive/AssetView";
import { VFlex } from "./VFlex";
import { SectionTextBox } from "./SectionTextBox";

export const SECTION_PADDING_X = 4;

// header text above a section
const SectionTitle: React.FunctionComponent<{
  title: string;
  json?: {
    name?: string;
    data: any;
  };
}> = (props) => {
  return (
    <SectionTextBox my={0} mb={-1} mt={3} py={0}>
      <Text color="gray.500" textAlign="left" fontSize="md" fontWeight="400">
        {props.title}
      </Text>
    </SectionTextBox>
  );
};

// transparent box with all the margins and paddings for a section
export const SectionBox: React.FunctionComponent<{} & IBoxProps> = (props) => {
  return (
    <VFlex position="relative" px={SECTION_PADDING_X} my={2} w="100%" {...props}>
      {props.children}
    </VFlex>
  );
};

// white card as a section
export const SectionCard: React.FunctionComponent<
  {
    title?: string | null;
    json?: {
      name?: string;
      data: any;
    };
    bgImageSource?: ImageSourcePropType;
    divider?: JSX.Element;
    withDivider?: boolean;
  } & IBoxProps
> = (props) => {
  const extraVProps = omit(props, ["title", "json", "bgImageSource", "divide"]);
  const divider = props.withDivider ? <Divider w="100%" /> : props.divider;
  return (
    <>
      {!!props.title && <SectionTitle title={props.title} json={props.json} />}
      <SectionBox>
        <VStack
          position="relative"
          bg="sectionBackground"
          borderRadius="md"
          borderWidth={0}
          borderColor="gray.100"
          overflow="hidden"
          divider={divider}
          {...extraVProps}
        >
          {props.bgImageSource ? (
            <AssetView
              position="absolute"
              top={0}
              left={0}
              source={props.bgImageSource}
              alt="Background"
              w={"100%"}
              h={"100%"}
              zIndex={-1}
              preserveAspectRatio={false}
            />
          ) : null}
          {props.children}
        </VStack>
      </SectionBox>
    </>
  );
};

import { Box, Text } from "native-base";
import React from "react";
import { HFlex } from "../../../../components/layout/HFlex";
import { ContainerDoc } from "../../../../model/ContainerDoc";
import { BowlEmptyIcon } from "../../../../components/icons/Icons";
import { VFlex } from "../../../../components/layout/VFlex";

export const ContainerList: React.FunctionComponent<{
  containers: ContainerDoc[];
  showProductInfo?: boolean;
  emptyMsg?: string;
  continueMsg?: string;
}> = (props) => {
  const msg = props.containers.length > 0 ? props.continueMsg : props.emptyMsg;

  return (
    <VFlex flex={1}>
      {props.containers.map((item) => {
        return (
          <HFlex key={item.id} px={3} py={1} alignItems="center">
            <Box marginTop={-2} marginRight={2}>
              <BowlEmptyIcon size="2xl" color="primaryText" />
            </Box>
            <Text color="primaryText" fontSize="md" fontWeight="600">
              {item.name}
              {props.showProductInfo ? ` (${item.productId})` : null}
            </Text>
          </HFlex>
        );
      })}
      {!!msg && (
        <HFlex key={"msg"} px={3} py={1} alignItems="center">
          <Box marginTop={-2} marginRight={2}>
            <BowlEmptyIcon size="2xl" color="muted.400" />
          </Box>
          <Text color="muted.400" fontSize="sm" fontWeight="600" noOfLines={2}>
            {msg}
          </Text>
        </HFlex>
      )}
    </VFlex>
  );
};

import { Flex, Pressable, Text } from "native-base";
import React from "react";
import { useCustomer } from "../../../model/useCustomer";
import { ifNotProduction } from "../../../lib/firebase/ifEnv";
import { SectionBox } from "../../../components/layout/Sections";

export const HelloSection = (props: { onLongPress?: () => void }) => {
  const { profile } = useCustomer();

  return (
    <SectionBox mt={3} mb={1}>
      <Pressable onPress={ifNotProduction(props.onLongPress)}>
        <Flex
          alignSelf="flex-start"
          flexDir="column"
          px={4}
          py={2}
          bg="accentLightBlue"
          borderRadius="md"
        >
          <Text fontWeight="700" fontSize="sm">
            {profile.name ? `Hello ${profile.name}!` : "Hello!"}
          </Text>
        </Flex>
      </Pressable>
    </SectionBox>
  );
};

import { SectionBox } from "./Sections";
import { IBoxProps, Text } from "native-base";

export type SectionMsgType = "text" | "error" | "muted";

export const SectionMsg: React.FC<
  {
    text: string | undefined | null;
    type?: SectionMsgType;
    textSize?: "sm" | "md";
  } & IBoxProps
> = (props) => {
  const type = props.type ?? "text";
  const textColor =
    type === "text" ? "primaryText" : type === "error" ? "errorMessage" : "gray.400";

  return (
    <SectionBox mt={-1} alignContent="center" {...props}>
      <Text
        flexGrow={1}
        px={4}
        w="full"
        color={textColor}
        textAlign={type === "muted" ? "left" : "center"}
        fontSize={props.textSize ?? "sm"}
      >
        {props.text ? props.text : " "}
      </Text>
    </SectionBox>
  );
};

export const SectionFooter = (props: { text: string | undefined | null } & IBoxProps) => {
  return <SectionMsg {...props} type="muted" />;
};

export const SectionError = (props: { text: string | undefined | null } & IBoxProps) => {
  return <SectionMsg {...props} type="error" />;
};

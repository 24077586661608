import firebase from "firebase/compat/app";
import { Text } from "native-base";
import React, { useEffect, useState } from "react";
import { SectionBox } from "../../../components/layout/Sections";
import { BannerImage } from "../../../components/layout/BannerImage";
import { TCSection } from "../../../components/footer/TCSection";
import { ProviderButton } from "./ProviderButton";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { isNativeIOS } from "../../../util/deviceInfo";
import { getPreferredProvider, setAttemptProvider } from "../../../lib/auth/authState";
import { useAuthCustomer } from "../../../model/useCustomer";
import { ProviderConfig } from "./ProviderConfigTypes";
import { useAppleAuthProviderConfig } from "./useAppleAuth";
import { useGoogleAuthProviderConfig } from "./useGoogleAuth";
import { SectionError } from "../../../components/layout/SectionMsg";
import { Screen } from "../../../components/layout/Screen";
import { PreambleScreenProps } from "../preambleStack";
import { appAnalytics } from "../../../lib/analytics/analytics";
import { EmailIcon, PhoneIcon } from "../../../components/icons/Icons";

const useAuthProviders = (): Record<string, ProviderConfig> => {
  const googleProviderConfig = useGoogleAuthProviderConfig();
  const appleProviderConfig = useAppleAuthProviderConfig();

  const providers: Record<string, ProviderConfig> = {};
  Object.assign(providers, {
    [firebase.auth.EmailAuthProvider.PROVIDER_ID]: {
      button: {
        label: "Email",
        color: "#db4437",
        icon: EmailIcon,
      },
      screen: "EmailSignIn",
    },
    [firebase.auth.PhoneAuthProvider.PROVIDER_ID]: {
      button: {
        label: "Phone Number",
        color: "#02bd7e",
        icon: PhoneIcon,
      },
      screen: "PhoneSignIn",
    },
    [firebase.auth.GoogleAuthProvider.PROVIDER_ID]: googleProviderConfig,
  });

  // Apple only for native ios or embedded web on ios
  if (isNativeIOS()) {
    Object.assign(providers, { "apple.com": appleProviderConfig });
  }

  return providers;
};

export const SignInScreen = ({ navigation, route }: PreambleScreenProps<"SignIn">) => {
  const closeScreen = useCloseScreen();
  const providerConfigs = useAuthProviders();
  const [recommendedProviderId, setRecommendedProviderId] = useState(
    determineRecommendedProviderId()
  );
  const loadingCustomer = useAuthCustomer((s) => s.isLoading);
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [msg, setMsg] = useState<string | null>(null);

  function determineRecommendedProviderId(): string {
    const firstProvider = Object.keys(providerConfigs)[0];
    let providerId = getPreferredProvider() ?? firstProvider;
    if (providerId in providerConfigs) return providerId;
    return firstProvider;
  }

  async function handleAuthProviderSelect(providerId: string): Promise<void> {
    setSelectedProviderId(providerId);
    // NOTE: we only store here that there was an attempt to sign-in with a specific
    //       provider. the actual preferred provider is set in authState. see comment
    //       there, why done in that way.
    setAttemptProvider(providerId);

    // track the provider in analytics
    appAnalytics().eventSignInProvider(providerId);

    // this clears an error messages
    navigation.setParams({ otherProviderEmail: null, otherProviderId: undefined });

    const providerConfig = providerConfigs[providerId];
    if ("screen" in providerConfig) {
      // provider requires a custom screen
      navigation.navigate(providerConfig.screen);
    } else {
      // call the providers modal prompt
      setProcessing(true);
      const result = await providerConfig.promptAsync();

      // when we get an auht, keep the processing flag. the navigatiomn will swap out the buttons
      // anyways, but it might take 1-2 secs. just keep the UI disabled during that time, if failure
      // enable the buttons again
      if (!result) setProcessing(false);
    }
  }

  useEffect(() => {
    if (route.params?.otherProviderEmail && route.params?.otherProviderId) {
      setMsg(
        `An account for ${route.params.otherProviderEmail} already exists. Please use ${route.params.otherProviderId} to sign in.`
      );
      setRecommendedProviderId(route.params.otherProviderId);
    } else {
      setMsg(null);
    }
  }, [route.params?.otherProviderEmail, route.params?.otherProviderId]);

  function renderButton(providerId: string) {
    const providerConfig = providerConfigs[providerId];
    if ("renderButton" in providerConfig) {
      // custom button provided
      return providerConfig.renderButton(processing, () => handleAuthProviderSelect(providerId));
    } else {
      // ... or config for standard button
      return (
        <ProviderButton
          my={1}
          key={providerId}
          isDisabled={loadingCustomer || processing}
          isLoading={(loadingCustomer || processing) && providerId === selectedProviderId}
          label={providerConfig.button!.label}
          providerColor={providerConfig.button!.color}
          icon={providerConfig.button!.icon}
          onPress={() => handleAuthProviderSelect(providerId)}
        />
      );
    }
  }

  function renderButtonSection() {
    return (
      <>
        <SectionError pt={2} mb={0} text={msg} />
        <SectionBox py={2}>
          {renderButton(recommendedProviderId)}
          <Text my={2} fontSize="xs" textAlign="center">
            - or -
          </Text>
          {Object.getOwnPropertyNames(providerConfigs).map((providerId) => {
            if (providerId !== recommendedProviderId) return renderButton(providerId);
          })}
        </SectionBox>
      </>
    );
  }

  return (
    <Screen name="Sign In" onBack={closeScreen}>
      <BannerImage image={require("assets/illustrations/Welcome.svg")} />
      {renderButtonSection()}
      <TCSection />
    </Screen>
  );
};

export default SignInScreen;

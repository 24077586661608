import { Text } from "native-base";
import React from "react";
import { Linking } from "react-native";
import { SectionCard, SectionBox } from "../../../components/layout/Sections";
import { VSpace } from "../../../components/layout/VSpace";
import { Screen } from "../../../components/layout/Screen";
import { BannerImage } from "../../../components/layout/BannerImage";
import { PreambleScreenProps } from "../preambleStack";
import { SectionPrimaryButton } from "../../../components/layout/SectionPrimaryButton";
import { SectionSecondaryButton } from "../../../components/layout/SectionSecondaryButton";

export const PremableItemInfoScreen = ({ navigation, route }: PreambleScreenProps<"ItemInfo">) => {
  function handleAction() {
    navigation.replace("SignIn", {});
  }

  function handleLearnMore() {
    Linking.openURL("https://www.recirclable.com");
  }

  return (
    <Screen name="Item Info" backMode="close">
      <BannerImage image={require("assets/illustrations/QR-Code.svg")} />
      <SectionBox>
        <Text mx={4} my={2} textAlign="center" fontSize="lg" fontWeight="600">
          You scanned the QR code on a bowl. What would you like to do?
        </Text>
      </SectionBox>
      <SectionPrimaryButton onPress={handleAction} my={3} label="Sign In/Up" />
      <SectionCard px={3} py={2}>
        <Text mb={1} textAlign="center" fontSize="lg" fontWeight="700">
          Borrow a bowl:
        </Text>
        <Text>
          Please sign In/Up and then scan the LARGE QR CODE displayed at the restaurant checkout to
          proceed with borrowing bowls.
        </Text>
      </SectionCard>
      <SectionCard px={3} py={2}>
        <Text mb={1} textAlign="center" fontSize="lg" fontWeight="700">
          Return a bowl
        </Text>
        <Text>
          Please sign In/Up and then scan the LARGE QR CODE displayed at the Recirclable return bin.
        </Text>
      </SectionCard>
      <SectionSecondaryButton
        onPress={handleLearnMore}
        my={3}
        label="Learn more about Recirclable"
      />
    </Screen>
  );
};

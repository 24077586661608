import { useNavigationState } from "@react-navigation/native";
import { Box, ScrollView, StatusBar } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { ScreenTitle } from "./ScreenTitle";
import { VSpace } from "./VSpace";
import { Header } from "../navigation/Header";
import { useCloseScreen } from "../navigation/useCloseScreen";

export type ScreenProps = {
  name: string;
  showTitle?: boolean;
  backMode?: "none" | "close" | "back";
  onBack?: () => void;
  right?: React.ReactNode;
  overlay?: () => JSX.Element;
  children?: JSX.Element | JSX.Element[] | string | any;
};

export const Screen = (props: ScreenProps) => {
  const closeScreen = useCloseScreen();

  // determine Admin and Partner label
  const navState = useNavigationState((state) => state);
  const isAdmin = navState.routes.filter((route) => route.name === "AdminHome").length > 0;
  const isPartner = navState.routes.filter((route) => route.name === "PartnerHome").length > 0;
  const label = isAdmin ? "Admin" : isPartner ? "Partner" : undefined;

  // determine whether in a Tab navigator
  const isTab = navState.type === "tab";

  // determine actual back mode (note: we don't check a canGoBack() because there or some situation
  // with deep link or with errors, where there is no screen on stack, but we still want to go back.
  // back happens then with resetting the stack.)
  const backMode = props.backMode ?? "back";

  // "go back" action
  const onBack = props.onBack ?? closeScreen;

  return (
    <SafeAreaView
      edges={Platform.select({ android: ["top", "bottom"], default: ["top"] })}
      style={{
        backgroundColor: "white",
      }}
    >
      <StatusBar barStyle={"dark-content"} backgroundColor={"white"} translucent={false} />
      {props.overlay ? props.overlay() : null}
      <Box
        key={props.name}
        w="100%"
        h="100%"
        alignSelf="center"
        bg="screenBackground"
        overflow="hidden"
        _web={{ h: "100vh" }}
      >
        {isTab ? (
          <Header title={"Recirclable"} right={props.right} />
        ) : (
          <Header
            title={"Recirclable"}
            backMode={backMode}
            onBack={onBack}
            right={props.right}
            label={label}
          />
        )}

        <ScrollView
          px={[0, 0, 4, 16]}
          showsVerticalScrollIndicator={false}
          width="100%"
          height="100%"
        >
          {props.showTitle ? <ScreenTitle title={props.name} /> : null}
          {props.children}
          {/* extra space helps with reaching elements at the botton, which might be covered by OS widgets */}
          <VSpace _web={{ h: isTab ? "120px" : "80px" }} h={isTab ? "60px" : "40px"} />
        </ScrollView>
      </Box>
    </SafeAreaView>
  );
};

import React, { useEffect, useState } from "react";
import { SectionBox } from "../../../components/layout/Sections";
import { ScreenTitle } from "../../../components/layout/ScreenTitle";
import { VSpace } from "../../../components/layout/VSpace";
import { CheckoutLocationSection } from "./CheckoutLocationSection";
import { LargeCounterInput } from "../../../components/primitive/LargeCounterInput";
import { appAnalytics } from "../../../lib/analytics/analytics";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { MAX_BORROW_QUANTITY } from "./constantsCheckout";
import { useBorrow } from "./useBorrow";
import { SectionError } from "../../../components/layout/SectionMsg";

export const SelfBorrowScreen = ({ navigation, route }: FrontAppScreenProps<"PointBorrow">) => {
  const [count, setCount] = useState<number>(0);
  const [borrowState, callSelfBorrow] = useBorrow();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  async function handleBorrow() {
    callSelfBorrow(route.params.pointId, count);
  }

  useEffect(() => {
    if (borrowState.value?.transaction) {
      appAnalytics().eventBorrow(count);

      // as next screen show the receipt
      navigation.replace("ShowReceipt", {
        transactionId: borrowState.value.transaction.id,
        transaction: borrowState.value.transaction,
      });
    } else if (borrowState.error) {
      setErrorMsg("An error occurred. Please try again.");
    }
  }, [borrowState.value, borrowState.error]);

  return (
    <Screen name="Borrow" backMode="close">
      <ScreenTitle title="Borrow Bowls" />
      <CheckoutLocationSection
        locationId={route.params.locationId}
        imageSource={require("assets/illustrations/Hands-Grabing-Bowl.svg")}
        imageAlt="Illustration Borrow Bowl"
      />
      <VSpace h="20px" />
      <SectionBox>
        <LargeCounterInput
          count={count}
          max={MAX_BORROW_QUANTITY}
          onChange={setCount}
          onSubmit={handleBorrow}
          busy={borrowState.loading}
          message="How many bowls are you borrowing?"
          label="Borrow Bowls"
        />
        <SectionError text={errorMsg} />
      </SectionBox>
    </Screen>
  );
};

import { Box, Button, Divider, Text } from "native-base";
import React from "react";
import { useLoadTransactionsSince } from "../../../model/useTransactions";
import { SectionCard } from "../../../components/layout/Sections";
import { HFlex } from "../../../components/layout/HFlex";
import { SectionMsg } from "../../../components/layout/SectionMsg";
import { VFlex } from "../../../components/layout/VFlex";
import { isMaxAge, daysUntil } from "../../../util/days";
import { friendlyFuture, friendlyPast } from "../../../util/format";
import { ONE_DAY } from "../../../util/constants";
import { VTransactionDoc } from "../../../model/VTransactionDoc";
import { ActivitySpinner } from "../../../components/primitive/ActivitySpinner";
import {
  BowlEmptyIcon,
  BowlFullIcon,
  CardIcon,
  ReceiptIcon,
  VoidIcon,
} from "../../../components/icons/Icons";

const ActivityLine = (props: {
  text: string;
  dueQty?: number;
  dueDate?: Date;
  icon: JSX.Element;
  locationName?: string;
  date: Date;
  onShowReceipt?: () => void;
}) => {
  const isDueSoon = !!props.dueDate && daysUntil(props.dueDate) < 3;

  return (
    <HFlex px={4} py={2} my={1} alignItems="center" w="100%">
      {props.icon}
      <VFlex flex={1} pl={3}>
        <HFlex justifyContent="space-between" alignContent="center" w="100%">
          <Text fontSize="md" fontWeight="700">
            {props.locationName ?? ""}
          </Text>
          <Text fontSize="sm" fontWeight="400">
            {friendlyPast(props.date)}
          </Text>
        </HFlex>
        <Text color="primaryText" fontSize="sm" fontWeight="400">
          {props.text}
        </Text>
        {!!props.dueQty && (
          <HFlex mt={1} mb={-1} justifyContent="space-between" alignItems="center" w="100%">
            {props.onShowReceipt ? (
              <Button
                variant="unstyled"
                p={1}
                mr={-2}
                bg={"accentLightMediumGreen"}
                _pressed={{ bg: "accentLightGreen" }}
                onPress={props.onShowReceipt}
              >
                <HFlex alignContent="center" w="100%">
                  <Text mx={1} color="primaryText" fontSize="sm">
                    Show Receipt
                  </Text>
                  <ReceiptIcon mx={1} color="primaryText" size="md" />
                </HFlex>
              </Button>
            ) : (
              <Box />
            )}
            <Text
              py={1}
              color={isDueSoon ? "danger.800" : "primaryText"}
              fontSize="sm"
              fontWeight="400"
            >
              {"Return "}
              <Text fontSize="sm" fontWeight="700">
                {props.dueQty}
              </Text>
              {" by "}
              <Text fontSize="sm" fontWeight="700">
                {friendlyFuture(props.dueDate!)}
              </Text>
            </Text>
          </HFlex>
        )}
      </VFlex>
    </HFlex>
  );
};

const ActivityItem = (props: { transaction: VTransactionDoc }) => {
  let icon: JSX.Element;
  let text = "";
  let locationName = "";

  switch (props.transaction.type) {
    case "borrow":
      locationName = props.transaction.locationName ?? "";
      text = `${props.transaction.itemQty} borrowed`;
      icon = <BowlFullIcon color="primaryText" size="2xl" />;
      break;

    case "return":
      locationName = props.transaction.locationName ?? "";
      text = `${-props.transaction.itemQty} returned`;
      if (props.transaction.creditQty) {
        text += ` + ${props.transaction.creditQty} extra`;
      }
      icon = <BowlEmptyIcon color="primaryText" size="2xl" />;
      break;

    case "expire":
      locationName = "Recirclable";
      text = `${props.transaction.purchaseQty} purchased`;
      icon = <CardIcon color="primaryText" size="2xl" />;
      break;

    case "void":
      locationName = "Recirclable";
      text += `${props.transaction.purchaseQty ? -props.transaction.purchaseQty : 0} refunded`;
      icon = <VoidIcon color="primaryText" size="2xl" />;
      break;
  }

  return (
    <ActivityLine
      icon={icon!}
      text={text}
      locationName={locationName}
      date={props.transaction.transactionAt}
    />
  );
};

const ActivityOpenBorrowItem = (props: {
  transaction: VTransactionDoc;
  onShowReceipt: (transactionId: string) => void;
}) => {
  let text = `${props.transaction.itemQty} borrowed`;
  let outstandingQty = props.transaction.outstandingQty ?? 0;
  if (outstandingQty < props.transaction.itemQty) {
    text += `, ${props.transaction.itemQty - outstandingQty} already returned`;
  }

  const showReceipt = isMaxAge(props.transaction.transactionAt, ONE_DAY);

  return (
    <ActivityLine
      icon={<BowlFullIcon color="primaryText" size="2xl" />}
      text={text}
      dueQty={props.transaction.outstandingQty}
      dueDate={props.transaction.expiresAt}
      locationName={props.transaction.locationName}
      date={props.transaction.transactionAt}
      onShowReceipt={showReceipt ? () => props.onShowReceipt(props.transaction.id) : undefined}
    />
  );
};

const ActivityOpenBorrowList = (props: {
  transactions: VTransactionDoc[];
  onShowReceipt: (transactionId: string) => void;
}) => {
  return (
    <Box flexGrow={1} w="100%" py={2}>
      <SectionCard title={`Your unreturned borrows`} divider={<Divider size="1px" w="100%" />}>
        {props.transactions?.map((transaction) => {
          return (
            <ActivityOpenBorrowItem
              key={transaction.id}
              transaction={transaction}
              onShowReceipt={props.onShowReceipt}
            />
          );
        })}
      </SectionCard>
    </Box>
  );
};

const ActivityOtherList = (props: { transactions: VTransactionDoc[] }) => {
  return (
    <Box flexGrow={1} w="100%" py={2}>
      <SectionCard
        title={`Your activity of the last ${TRANSACTIONS_SINCE_DAYS} days`}
        divider={<Divider size="1px" w="100%" />}
      >
        {props.transactions?.map((transaction) => {
          return <ActivityItem key={transaction.id} transaction={transaction} />;
        })}
      </SectionCard>
    </Box>
  );
};

const ActivityList = (props: {
  transactions: VTransactionDoc[];
  onShowReceipt: (transactionId: string) => void;
}) => {
  const openTransactions = props.transactions.filter(
    (t) => t.type === "borrow" && t.outstandingQty
  );
  const otherTransactions = props.transactions.filter((t) => !openTransactions.includes(t as any));

  return (
    <>
      {openTransactions.length > 0 ? (
        <ActivityOpenBorrowList
          transactions={openTransactions}
          onShowReceipt={props.onShowReceipt}
        />
      ) : null}
      {otherTransactions.length > 0 ? <ActivityOtherList transactions={otherTransactions} /> : null}
    </>
  );
};

const TRANSACTIONS_SINCE_DAYS = 90;
export const ActivityBlock = (props: {
  accountId: string;
  hasTransactions: boolean;
  onShowReceipt: (transactionId: string) => void;
}) => {
  const { loading, value: transactions } = useLoadTransactionsSince(
    props.accountId,
    TRANSACTIONS_SINCE_DAYS * ONE_DAY
  );

  if (loading) {
    return <ActivitySpinner />;
  } else {
    const empty = transactions && transactions.length === 0;

    if (empty && !props.hasTransactions) {
      // never ordered
      return (
        <SectionMsg
          mt={6}
          text="No orders yet! Don't forget to join the reuse revolution."
          textSize="md"
        />
      );
    } else if (empty && props.hasTransactions) {
      // ordered in the past, just not recently
      return (
        <SectionMsg
          mt={6}
          text={`No orders in ${TRANSACTIONS_SINCE_DAYS} days! Don't forget to continue the reuse revolution.`}
          textSize="md"
        />
      );
    } else {
      return <ActivityList transactions={transactions!} onShowReceipt={props.onShowReceipt} />;
    }
  }
};

import React from "react";
import { SectionBox } from "./components/layout/Sections";
import { Image, Text } from "native-base";
import { VFlex } from "./components/layout/VFlex";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { safe } from "./util/safe";
import { isDevelopment, isEmulator } from "./lib/firebase/ifEnv";
import { ONE_DAY, ONE_MIN } from "./util/constants";
import { UndecoratedScreen } from "./components/layout/UndecoratedScreen";
import { HFlex } from "./components/layout/HFlex";
import { BannerImage } from "./components/layout/BannerImage";
import { sentry } from "./lib/sentry/sentry";
import { SectionPrimaryButton } from "./components/layout/SectionPrimaryButton";

const ENABLED = true;
const DEBUG_ALWAYS = false;

export const AppStoreBanner = (props: { children?: React.ReactNode }) => {
  const [bannerAt, setBannertAt] = useLocalStorage("DID_SHOW_APP_STORE_BANNER", 0);
  const showBanner =
    ENABLED &&
    (DEBUG_ALWAYS ||
      !bannerAt ||
      safe.parseInt(bannerAt) < Date.now() - (isEmulator() || isDevelopment() ? ONE_MIN : ONE_DAY));

  const handleDismiss = React.useCallback(() => {
    sentry().captureException(new Error(`customer dismissed app download banner`));
    setBannertAt(Date.now());
  }, []);

  if (showBanner) {
    return (
      <UndecoratedScreen name="Install Recirclable App">
        <BannerImage h={100} mt={0} image={require("assets/logo/logo-with-tm.svg")} />
        <VFlex>
          <SectionBox>
            <VFlex p={4}>
              <Text fontWeight="bold">
                Download the Recirclable App for the richest and most reliable experience:
              </Text>
              <Text>{"\u2022"} Quick performance</Text>
              <Text>{"\u2022"} Check your current balance at any time</Text>
              <Text>{"\u2022"} Order online from select restaurants</Text>
            </VFlex>
          </SectionBox>
          <SectionBox>
            <HFlex p={4} justifyContent="space-between">
              <a href="https://apps.apple.com/us/app/recirclable/id1638609156">
                <Image
                  alt="Download from App Store"
                  source={require("assets/appstores/app-store-badge.svg")}
                  resizeMode="contain"
                  width="152px"
                  height="50px"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.recirclable.recirclable">
                <Image
                  alt="Download from Play Store"
                  source={require("assets/appstores/google-play-badge.png")}
                  resizeMode="contain"
                  width="172px"
                  height="50px"
                />
              </a>
            </HFlex>
          </SectionBox>
        </VFlex>
        <SectionPrimaryButton my={4} onPress={handleDismiss} label="Skip for now" />
      </UndecoratedScreen>
    );
  } else {
    return props.children;
  }
};

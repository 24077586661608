import React, { useEffect } from "react";
import { SectionCard, SectionBox } from "../../../components/layout/Sections";
import { Heading, Text } from "native-base";
import { TCSection } from "../../../components/footer/TCSection";
import { BannerImage } from "../../../components/layout/BannerImage";
import { TwoButtonGroup } from "../../../components/primitive/TwoButtonGroup";
import { VersionSection } from "../../../components/footer/VersionSection";
import { authSignOut } from "../../../lib/auth/authState";
import { Screen } from "../../../components/layout/Screen";
import { PreambleScreenProps } from "../preambleStack";

export const Welcome1Screen = ({ navigation, route }: PreambleScreenProps<"Welcome1">) => {
  useEffect(() => {
    // just make sure no user is signed-in when we are on the welcome screen. this might happen in
    // some odd cases, most of the time error cases.
    authSignOut();
  }, []);

  return (
    <Screen name="Welcome" backMode="none">
      <BannerImage image={require("assets/logo/logo-with-tm.svg")} />
      <SectionCard>
        <Heading pt={6} fontSize="xl" textAlign="center">
          Welcome to Recirclable!
        </Heading>
        <Text mt={3} mx={4} mb={3} textAlign="center">
          A treat for yourself and the earth! Remove single-use waste from takeout. Best of all,
          it’s free! You won't be charged unless you forget to return the bowls after two weeks -
          but don’t worry, we’ll remind you!
        </Text>
      </SectionCard>
      <SectionBox alignItems="center">
        <TwoButtonGroup
          _left={{
            testID: "welcome1-signin",
            label: "Sign In/Up",
            onPress: () => navigation.navigate("SignIn", {}),
          }}
          _right={{
            testID: "welcome1-howitworks",
            label: "How it works?",
            onPress: () => navigation.navigate("Welcome2"),
          }}
        />
      </SectionBox>
      <TCSection />
      <VersionSection />
    </Screen>
  );
};

export default Welcome1Screen;

import { Box, Divider, Text } from "native-base";
import React, { useEffect } from "react";
import { RpcCheckoutTransactionType } from "../../../../../backend-types/src";
import { SectionBox } from "../../../components/layout/Sections";
import { SectionTextBox } from "../../../components/layout/SectionTextBox";
import { LabelValueRow } from "../../../components/primitive/LabelValueRow";
import { LottiePlayer } from "../../../components/primitive/LottiePlayer";
import { VFlex } from "../../../components/layout/VFlex";
import useAsync from "react-use/lib/useAsync";
import { Screen } from "../../../components/layout/Screen";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { WaitScreen } from "../../../components/layout/WaitScreen";
import { isProduction } from "../../../lib/firebase/ifEnv";
import { friendlyDate, friendlyPast } from "../../../util/format";
import { VTransactions } from "../../../model/VTransactionDoc";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { VerifyPhoneToast } from "../profile/VerifyPhoneToast";
import { useCustomer } from "../../../model/useCustomer";
import { docGet } from "../../../lib/firestore/fstore";
import { useAlerts } from "../../../components/modals/AlertProvider";
import { SectionPrimaryButton } from "../../../components/layout/SectionPrimaryButton";

const BorrowReceiptNrSection: React.FunctionComponent<{
  transaction: RpcCheckoutTransactionType;
}> = (props) => {
  const receiptNr = props.transaction.external?.receiptNr ?? "Self Borrow";
  return (
    <VFlex my={1} w="100%">
      <LabelValueRow label="Bowls" value={props.transaction.itemQty + " borrowed"} />
      {receiptNr ? <LabelValueRow label="Receipt Nr" value={receiptNr} /> : null}
      {props.transaction.external?.orderNr ? (
        <LabelValueRow label="Order Nr" value={props.transaction.external.orderNr} />
      ) : null}
    </VFlex>
  );
};

const ReturnReceiptNrSection: React.FunctionComponent<{
  transaction: RpcCheckoutTransactionType;
}> = (props) => {
  const receiptNr =
    props.transaction.external?.receiptNr ??
    (props.transaction.mode === "adjust" ? "Adjustment" : "Self Return");
  return (
    <VFlex my={1} w="100%">
      <LabelValueRow label="Bowls" value={Math.abs(props.transaction.itemQty) + " returned"} />
      {props.transaction.creditQty ? (
        <LabelValueRow label="" value={props.transaction.creditQty + " extra"} />
      ) : null}
      {receiptNr ? <LabelValueRow label="Receipt Nr" value={receiptNr} /> : null}
    </VFlex>
  );
};

const BorrowReceiptBody: React.FunctionComponent<{
  transaction: RpcCheckoutTransactionType;
  onDone: () => void;
}> = (props) => {
  return (
    <>
      {!isProduction() && (
        <SectionBox>
          <Box backgroundColor="amber.300" borderRadius={5}>
            <Text p={2} textAlign="center" fontWeight={700} fontSize="xl">
              TEST
            </Text>
          </Box>
        </SectionBox>
      )}
      <SectionBox mt={0}>
        <VFlex
          position="relative"
          my={3}
          p={2}
          bg="white"
          borderRadius="md"
          shadow={99}
          alignItems="center"
          justifyContent="center"
        >
          <LottiePlayer
            source={require("assets/animations/24703-food-animation.json")}
            height={180}
            width={180}
          />
          <Text position="absolute" opacity="1.0" fontWeight="700" fontSize={96}>
            {props.transaction.itemQty}
          </Text>
          <Text fontSize="lg">{friendlyPast(props.transaction.transactionAt)}</Text>
        </VFlex>
      </SectionBox>
      <SectionBox alignContent="center">
        <VFlex
          p={2}
          pb={1}
          bg="white"
          borderRadius="md"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize="sm" fontWeight="400" textAlign="center">
            Receipt from
          </Text>
          <Text fontSize="md" fontWeight="700" textAlign="center">
            {props.transaction.locationName}
          </Text>
          <Divider my={2} bg="gray.400" orientation="horizontal" size="1px" w="100%" />
          <BorrowReceiptNrSection transaction={props.transaction} />
          <Divider my={2} bg="gray.400" orientation="horizontal" size="1px" w="100%" />
          <Text fontSize="sm" fontWeight="400" textAlign="center">
            Return to any participating restaurant.
          </Text>
          <Text lineHeight="2xl" fontSize="md" fontWeight="700" textAlign="center">
            due by {friendlyDate(props.transaction.expiresAt!)}
          </Text>
        </VFlex>
      </SectionBox>
      <SectionTextBox mt={4}>
        <Text fontSize="md" fontWeight="400" textAlign="center">
          Please show this page to the cashier to validate your order and receive your bowls.
        </Text>
      </SectionTextBox>
      <SectionPrimaryButton onPress={props.onDone} label="Done" />
    </>
  );
};

const ReturnReceiptBody: React.FunctionComponent<{
  transaction: RpcCheckoutTransactionType;
  onDone: () => void;
}> = (props) => {
  const alerts = useAlerts();

  useEffect(() => {
    if (props.transaction.creditQty) {
      alerts.openAlertOk({
        title: "Returning Extra Bowls",
        body:
          `Thanks for returning ${props.transaction.creditQty} extra bowl(s). ` +
          "Please remember to checkout bowls every time you use Recirclable, to help us keep accurate inventory!",
      });
    }
  }, []);

  return (
    <>
      {!isProduction() ? (
        <SectionBox>
          <Box backgroundColor="amber.300" borderRadius={5}>
            <Text p={2} textAlign="center" fontWeight={700} fontSize="xl">
              TEST
            </Text>
          </Box>
        </SectionBox>
      ) : null}
      <SectionBox mt={0}>
        <VFlex
          position="relative"
          my={3}
          p={2}
          bg="white"
          borderRadius="md"
          shadow={99}
          alignItems="center"
          justifyContent="center"
        >
          <Box mt={-45} mb={-75}>
            <LottiePlayer
              source={require("assets/animations/lf30_editor_vohhhpiw.json")}
              width={280}
              height={280}
            />
          </Box>

          <Text position="absolute" opacity="1.0" fontWeight="700" fontSize={96}>
            {Math.abs(props.transaction.itemQty) + (props.transaction.creditQty ?? 0)}
          </Text>
          <Text fontSize="lg">{friendlyPast(props.transaction.transactionAt)}</Text>
        </VFlex>
      </SectionBox>
      <SectionBox alignContent="center">
        <VFlex p={2} bg="white" borderRadius="md" alignItems="center" justifyContent="center">
          <Text fontSize="sm" fontWeight="400" textAlign="center">
            Receipt from
          </Text>
          <Text fontSize="md" fontWeight="700" textAlign="center">
            {props.transaction.locationName}
          </Text>
          <Divider my={2} bg="gray.400" orientation="horizontal" size="1px" w="100%" />
          <ReturnReceiptNrSection transaction={props.transaction} />
          <Divider my={2} bg="gray.400" orientation="horizontal" size="1px" w="100%" />
          <Text mx={6} fontSize="sm" fontWeight="400" textAlign="center">
            Thank you for keeping Recirclable bowls circulating!
          </Text>
        </VFlex>
      </SectionBox>
      <SectionPrimaryButton my={4} onPress={props.onDone} label="Done" />
    </>
  );
};

export const ShowReceiptScreen = ({ navigation, route }: FrontAppScreenProps<"ShowReceipt">) => {
  const closeScreen = useCloseScreen();
  const { profile } = useCustomer();
  const { loading, value: transaction } = useAsync(async () => {
    // NOTE: we are passing a full object as parameter, which is highly discouraged. But we also pass
    // the id. We check here first whether we can read the object. If not, then we load from the
    // database. We do this effort to eliminate the short lack until v_transactions is updated.
    if (route.params.transaction?.id) {
      return route.params.transaction as RpcCheckoutTransactionType;
    } else {
      return (await docGet(VTransactions.doc(route.params.transactionId!))) as
        | RpcCheckoutTransactionType
        | undefined;
    }
  });

  if (loading) {
    return <WaitScreen name="Wait for Receipt" />;
  } else {
    let msg: string | null = null;
    if (transaction) {
      if (transaction.type !== "borrow" && transaction.type !== "return") {
        msg = "Upps. Something went wrong.";
      }
    } else {
      msg = "Upps. Receipt not found.";
    }

    return (
      <Screen
        name="Show Receipt"
        backMode="close"
        overlay={() => (
          <VerifyPhoneToast
            profile={profile}
            onPress={() => navigation.push("ProfileChangePhone")}
          />
        )}
      >
        {!transaction || !!msg ? (
          <Text textAlign="center">{msg}</Text>
        ) : transaction.type === "borrow" ? (
          <BorrowReceiptBody transaction={transaction} onDone={closeScreen} />
        ) : (
          <ReturnReceiptBody transaction={transaction} onDone={closeScreen} />
        )}
      </Screen>
    );
  }
};

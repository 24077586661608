import _omit from "lodash/omit";
import _pick from "lodash/pick";
import { SectionBox } from "./Sections";
import { IButtonProps } from "native-base";
import { SecondaryButton } from "../primitive/SecondaryButton";

export function SectionSecondaryButton(props: { label: string } & IButtonProps) {
  const sectionProps = _pick(props, ["mx", "my", "mt", "mb", "ml", "mr"]);
  const buttonProps = _omit(props, ["mx", "my", "mt", "mb", "ml", "mr"]);
  return (
    <SectionBox {...sectionProps}>
      <SecondaryButton {...buttonProps} />
    </SectionBox>
  );
}

import React, { useCallback } from "react";
import { IconButton } from "native-base";
import { HFlex } from "../layout/HFlex";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";
import { FrontAppScreenParamList } from "../../frontAppStack";
import { AccountIcon, DashboardIcon, StoreIcon } from "../icons/Icons";
import { appAdminDomain } from "../../lib/expo/appConfig";
import { useRoles } from "../../lib/auth/roleState";
import { useAuthState } from "../../lib/auth/authState";

export const BackOfficeHeaderButtons = () => {
  const userId = useAuthState((s) => s.user?.uid);
  const isManager = useRoles((s) => s.isManager);
  const isPartner = useRoles((s) => s.isPartner);
  const navigation = useNavigation<NavigationProp<FrontAppScreenParamList>>();

  const handelPartnerHome = useCallback(() => {
    if (Platform.OS === "web") {
      window.location.href = `https://${appAdminDomain()}/a/partner-home?__userId=${userId}`;
    } else {
      navigation.navigate("BackOffice", {
        label: "Partner",
        path: "/a/partner-home",
        title: "Dashboard",
      });
    }
  }, []);

  const handelAdminHome = useCallback(() => {
    if (Platform.OS === "web") {
      window.location.href = `https://${appAdminDomain()}/a/admin-home?__userId=${userId}`;
    } else {
      navigation.navigate("BackOffice", {
        label: "Admin",
        path: "/a/admin-home",
        title: "Admin Dashboard",
      });
    }
  }, []);

  const profileIcon = <AccountIcon size="xl" color="primaryText" />;
  const partnerIcon = <StoreIcon name="store" size="xl" color="primaryText" />;
  const adminIcon = <DashboardIcon size="xl" color="primaryText" />;

  return (
    <HFlex>
      {isManager && (
        <IconButton
          variant="unstyled"
          px={1}
          colorScheme="primary"
          icon={adminIcon}
          onPress={handelAdminHome}
        />
      )}
      {isPartner && (
        <IconButton
          variant="unstyled"
          px={1}
          colorScheme="primary"
          icon={partnerIcon}
          onPress={handelPartnerHome}
        />
      )}
      {Platform.OS === "web" && (
        <IconButton
          variant="unstyled"
          pl={1}
          colorScheme="primary"
          icon={profileIcon}
          onPress={() => navigation.navigate("Profile")}
        />
      )}
    </HFlex>
  );
};

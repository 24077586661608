import { Divider, Input } from "native-base";
import { useState } from "react";
import { useCustomer } from "../../../model/useCustomer";
import { SectionCard } from "../../../components/layout/Sections";
import { SectionError } from "../../../components/layout/SectionMsg";
import { Platform } from "react-native";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { fixPhone, validatePhoneInput } from "../../../util/phone";
import { FrontRpc } from "../../../lib/functions/rpc";
import { overlayWrite } from "../../../lib/firestore/overlay";
import { SectionPrimaryButton } from "../../../components/layout/SectionPrimaryButton";
import { SectionSecondaryButton } from "../../../components/layout/SectionSecondaryButton";

export function PhoneChangeBody(props: {
  isDisabled?: boolean;
  requestName?: boolean;
  codeRequested?: boolean;
  onInput?: (phone: string) => void;
  onSuccess: () => void;
}) {
  const { profile } = useCustomer();
  const [enableNameInput] = useState(props.requestName && !profile.name);
  const [nameInput, setNameInput] = useState<string | undefined>();
  const [phoneInput, setPhoneInput] = useState(profile.phone ?? "");
  const [code, setCode] = useState("");
  const [msg, setMsg] = useState<string | null>(null);
  const [internalCodeRequested, setCodeRequested] = useState<boolean>(false);
  const codeRequested =
    props.codeRequested !== undefined ? props.codeRequested : internalCodeRequested;

  // request code
  const [sendCodeState, callSendCode] = useAsyncFn(async (phone: string) => {
    try {
      phone = fixPhone(phone);
      const result = await FrontRpc.call("changePhone", { phone });
      if (result.success) {
        setCodeRequested(result.success);
        props.onInput?.(phone);
        setMsg(null);
      } else {
        console.error("error when preparing phone number for sign-in", result.msg);
        setMsg(result.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error("error when calling phoneSignIn function to send code", error);
      setMsg((error as any).message ?? "Something went wrong");
    }
  });

  // confirm text msg code
  const [verifyCodeState, callVerifyCode] = useAsyncFn(
    async (phone: string, name: string | undefined, code: string) => {
      try {
        phone = fixPhone(phone);
        const result = await FrontRpc.call("changePhone", { phone, name, code });
        if (result.success) {
          overlayWrite(profile, { name, phone, phoneVerified: true });
          props.onSuccess();
          setMsg(null);
        } else {
          console.error("error when verifying phone number for sign-in", result.msg);
          setMsg(result.msg ?? "Something went wrong");
        }
      } catch (error) {
        console.error("error when calling phoneSignIn function to verify code", error);
        setMsg((error as any).message ?? "Something went wrong");
      }
    }
  );

  function handlePrepare(event: any) {
    callSendCode(phoneInput);
  }

  function handleVerifyCode(event: any) {
    callVerifyCode(phoneInput, nameInput, code);
  }

  if (!codeRequested) {
    return (
      <>
        <SectionCard divider={<Divider size="1px" w="100%" />}>
          {enableNameInput && (
            <Input
              nativeID="name"
              isDisabled={props.isDisabled || codeRequested}
              flex={1}
              placeholder={"Enter First & Last Name"}
              placeholderTextColor="gray.400"
              returnKeyType="done"
              autoComplete="name"
              autoCorrect={false}
              value={nameInput}
              onChangeText={setNameInput}
            />
          )}
          <Input
            nativeID="phone"
            isDisabled={props.isDisabled || codeRequested}
            keyboardType="phone-pad"
            placeholder={`Enter Phone Number${enableNameInput ? " (required)" : ""}`}
            placeholderTextColor="gray.400"
            autoComplete="tel"
            value={phoneInput}
            onChangeText={setPhoneInput}
          />
        </SectionCard>
        <SectionError text={msg} />

        {!codeRequested && (
          <SectionPrimaryButton
            my={3}
            label={"Send Verification Code"}
            isDisabled={
              props.isDisabled || !validatePhoneInput(phoneInput) || sendCodeState.loading
            }
            isLoading={sendCodeState.loading}
            onPress={handlePrepare}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <SectionCard divider={<Divider size="1px" w="100%" />}>
          <Input
            nativeID="code"
            keyboardType="number-pad"
            placeholder="Enter Code from Text Message"
            placeholderTextColor="gray.400"
            // iOS shows the auto complete for a text message code only with "one-time-code"
            autoComplete={Platform.OS === "ios" ? ("one-time-code" as any) : "sms-otp"}
            value={code}
            isDisabled={props.isDisabled}
            onChangeText={setCode}
          />
        </SectionCard>
        <SectionError text={msg} />

        <SectionPrimaryButton
          mt={3}
          mb={1}
          label={"Verify Code"}
          isDisabled={props.isDisabled || !code?.length || verifyCodeState.loading}
          isLoading={verifyCodeState.loading}
          onPress={handleVerifyCode}
        />
        <SectionSecondaryButton
          my={1}
          label={"Resend Verification Code"}
          isDisabled={props.isDisabled || phoneInput?.length < 10 || sendCodeState.loading}
          onPress={handlePrepare}
        />
      </>
    );
  }
}

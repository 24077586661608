import React from "react";
import { Box, Link, Text } from "native-base";
import { ExpirationSummary } from "../../../model/VBalanceDoc";
import { VFlex } from "../../../components/layout/VFlex";
import { HFlex } from "../../../components/layout/HFlex";

function dueMessage(summary: ExpirationSummary) {
  let message: string | undefined;
  let count: number = 0;

  if (summary.overdue || summary.today) {
    count = summary.overdue + summary.today;
    message = "today";
  } else if (summary.tomorrow) {
    count = summary.tomorrow;
    message = "tomorrow";
  } else if (summary.days2) {
    count = summary.days2;
    message = "in 2 days";
  } else if (summary.week) {
    count = summary.week;
    message = "within a week";
  } else if (summary.rest) {
    count = summary.rest;
    message = "in more than a week";
  }

  if (count === 1) {
    message = `Your bowl is due for return ${message}`;
  } else if (count === summary.sum) {
    message = `All bowls are due for return ${message}`;
  } else {
    message = `${count} bowls are due for return ${message}`;
  }
  return message;
}

export const ModalReminderBody = (props: { summary: ExpirationSummary }) => {
  return (
    <VFlex alignItems="center">
      {props.summary.today > 0 ? (
        <HFlex p={2} justifyContent="flex-start" alignItems="flex-end">
          <Box bg="primaryText" borderRadius="md" w="53px" h="53px">
            <Text
              lineHeight="53px"
              color="white"
              textAlign="center"
              // FIXME: verticalAlign="middle"
              fontSize="3xl"
              fontWeight="700"
            >
              {props.summary.today}
            </Text>
          </Box>
          <Text px={4} py={2} color="primaryText" fontSize="xl" fontWeight="700">
            Bowl(s) due today
          </Text>
        </HFlex>
      ) : (
        <Text pt={3} pb={1} fontSize="lg" fontWeight="700" textAlign="center">
          {dueMessage(props.summary)}
        </Text>
      )}
      <Text mt={4} my={2} fontSize="md" fontWeight="600" textAlign="center">
        <Text>Please rinse and return to any </Text>
        <Link
          href="https://www.recirclable.com/restaurants"
          isUnderlined
          _text={{ fontSize: "md", fontWeight: 700 }}
        >
          participating restaurants
        </Link>
        <Text>. Thank you!</Text>
      </Text>
    </VFlex>
  );
};

import { Box, Text } from "native-base";
import React from "react";
import { HFlex } from "../layout/HFlex";

export const LabelValueRow: React.FunctionComponent<{
  label: string;
  value: string | number;
}> = (props) => {
  return (
    <HFlex w="100%" alignItems="center">
      <Box flex={1} mx={1} w="100%">
        <Text fontSize="sm" fontWeight="400" textAlign="right">
          {props.label}
        </Text>
      </Box>
      <Box flex={1} mx={1} w="100%">
        <Text fontSize="md" fontWeight="700" textAlign="left">
          {props.value}
        </Text>
      </Box>
    </HFlex>
  );
};

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AlertOk } from "./AlertOk";
import { FUNC_NOOP } from "../../util/constants";
import { AlertYesNo } from "./AlertYesNo";
import { ImageSourcePropType } from "react-native";

type AlertOkOptions<R = any> = {
  title?: string;
  body?: string | (() => JSX.Element);
  inputBody?: (props: { initialValue: R; onValueChange: (value: R) => void }) => JSX.Element;
  imageSource?: ImageSourcePropType;
  initialValue?: R;
  ok?: string;
};

type AlertYesNoOptions<R = any> = {
  title?: string;
  body?: string | (() => JSX.Element);
  inputBody?: (props: { initialValue: R; onValueChange: (value: R) => void }) => JSX.Element;
  imageSource?: ImageSourcePropType;
  initialValue?: R;
  yes?: string;
  no?: string;
};

type YesNoCallbackType<R = any> = (yesno: boolean | null | undefined, inputValue?: R) => void;

type AlertContextType = {
  openAlertOk<R = any>(opts: AlertOkOptions<R>, callback?: YesNoCallbackType<R>): void;
  openAlertYesNo<R = any>(opts: AlertYesNoOptions<R>, callback: YesNoCallbackType<R>): void;
  closeAlert(): void;
};

const AlertContext = createContext<AlertContextType | null>(null);

export const AlertProvider = (props: { children?: JSX.Element | JSX.Element[] }) => {
  const [alertOkOptions, setAlertOkOptions] = useState<AlertOkOptions | null>(null);
  const [alertYesNoOptions, setAlertYesNoOptions] = useState<AlertYesNoOptions | null>(null);
  const [inputValue, setInputValue] = useState<any>(null);
  const callbackRef = useRef<YesNoCallbackType>(FUNC_NOOP);

  const closeAlert = useCallback(
    (yesno?: boolean | null) => {
      callbackRef.current?.(yesno, inputValue);
      setAlertOkOptions(null);
      setAlertYesNoOptions(null);
      callbackRef.current = FUNC_NOOP;
    },
    [inputValue]
  );

  const openAlertOk = useCallback(
    (opts: AlertOkOptions, callback: YesNoCallbackType = FUNC_NOOP): void => {
      closeAlert();
      setInputValue(opts.initialValue);
      setAlertOkOptions(opts);
      callbackRef.current = callback;
    },
    [inputValue]
  );

  const openAlertYesNo = useCallback(
    (opts: AlertYesNoOptions, callback: YesNoCallbackType): void => {
      closeAlert();
      setInputValue(opts.initialValue);
      setAlertYesNoOptions(opts);
      callbackRef.current = callback;
    },
    [inputValue]
  );

  const handleValueChange = useCallback(
    (value: any) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const contextValue = useMemo(() => ({ openAlertOk, openAlertYesNo, closeAlert }), []);

  useEffect(() => {
    return () => {
      // when the provider is unmounted also close all alerts
      closeAlert();
    };
  }, []);

  return (
    <AlertContext.Provider value={contextValue}>
      {props.children}
      {!!alertOkOptions && (
        <AlertOk
          isOpen={!!alertOkOptions}
          title={alertOkOptions.title}
          body={alertOkOptions.body}
          inputBody={alertOkOptions.inputBody}
          imageSource={alertOkOptions.imageSource}
          primary={alertOkOptions.ok}
          initialValue={inputValue}
          onValueChange={handleValueChange}
          onClose={closeAlert}
        />
      )}
      {!!alertYesNoOptions && (
        <AlertYesNo
          isOpen={!!alertYesNoOptions}
          title={alertYesNoOptions.title}
          body={alertYesNoOptions.body}
          inputBody={alertYesNoOptions.inputBody}
          imageSource={alertYesNoOptions.imageSource}
          primary={alertYesNoOptions.yes}
          secondary={alertYesNoOptions.no}
          initialValue={inputValue}
          onValueChange={handleValueChange}
          onClose={closeAlert}
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlerts = () => {
  const alerts = useContext(AlertContext);

  useEffect(() => {
    return () => {
      // if the containing view is unmounted, then make sure also all alerts are closed
      alerts?.closeAlert();
    };
  }, []);

  return alerts!;
};

import React from "react";
import { AlertDialog, Text } from "native-base";
import { TwoButtonGroup } from "../primitive/TwoButtonGroup";
import { ImageSourcePropType } from "react-native";

export const AlertYesNo: React.FunctionComponent<{
  title?: string;
  body?: string | (() => JSX.Element);
  inputBody?: (props: { initialValue: any; onValueChange: (value: any) => void }) => JSX.Element;
  imageSource?: ImageSourcePropType;
  primary?: string;
  secondary?: string;
  isOpen: boolean;
  initialValue?: any;
  onValueChange?: (value: any) => void;
  onClose?: (yesno?: boolean) => void;
}> = (props) => {
  const yesLabel = props.primary ?? "Yes";
  const noLabel = props.secondary ?? "No";

  function renderBody() {
    if (typeof props.body === "string") {
      return <Text>{props.body}</Text>;
    } else if (typeof props.body === "function") {
      return props.body();
    } else if (props.inputBody && props.onValueChange) {
      return props.inputBody({
        initialValue: props.initialValue,
        onValueChange: props.onValueChange,
      });
    }
    return null;
  }

  const leastDestructiveRef = React.useRef(null);
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      size="xl"
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        {props.title && (
          <AlertDialog.Header textAlign="center" fontWeight="700">
            {props.title}
          </AlertDialog.Header>
        )}
        <AlertDialog.Body px={6} py={4}>
          {renderBody()}
        </AlertDialog.Body>
        <AlertDialog.Footer justifyContent="center">
          <TwoButtonGroup
            _left={{
              label: noLabel,
              onPress: () => props.onClose?.(false),
            }}
            _right={{
              label: yesLabel,
              onPress: () => props.onClose?.(true),
            }}
          />
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

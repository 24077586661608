import { Box, Center } from "native-base";
import React from "react";
import { UndecoratedScreen } from "./UndecoratedScreen";
import { ActivitySpinner } from "../primitive/ActivitySpinner";
import { ONE_SEC } from "../../util/constants";
import useError from "react-use/lib/useError";
import useTimeout from "react-use/lib/useTimeout";
import useTimeoutFn from "react-use/lib/useTimeoutFn";

export const WAIT_SCREEN_TIMEOUT = 20 * ONE_SEC;

export const WaitScreen: React.FunctionComponent<{
  name: string;
}> = (props) => {
  const dispatchError = useError();
  const [showSpinner] = useTimeout(1000); // Spinner after 1sec wait

  // timeout with error after 20sec. useTimeoutFn cancels the timer when the
  // component is umounted.
  useTimeoutFn(() => {
    dispatchError(new Error("WaitScreen timeout"));
  }, WAIT_SCREEN_TIMEOUT);

  return (
    <UndecoratedScreen name={props.name}>
      <Center w="100%" h="100%">
        {showSpinner() ? <ActivitySpinner color="primaryText" size="lg" /> : <Box />}
      </Center>
    </UndecoratedScreen>
  );
};

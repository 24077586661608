import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Divider, Flex, Input, KeyboardAvoidingView, Text } from "native-base";
import { firebaseAuth } from "../../../lib/firebase/fbenv";
import { SectionCard } from "../../../components/layout/Sections";
import { setPreferredProvider } from "../../../lib/auth/authState";
import { PasswordField } from "../../../components/primitive/PasswordField";
import { BannerImage } from "../../../components/layout/BannerImage";
import { TCSection } from "../../../components/footer/TCSection";
import { Screen } from "../../../components/layout/Screen";
import { appUniversalDomain } from "../../../lib/expo/appConfig";
import { Platform } from "react-native";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { useAuthCustomer } from "../../../model/useCustomer";
import { SectionError } from "../../../components/layout/SectionMsg";
import { PreambleScreenProps } from "../preambleStack";
import { FrontRpc } from "../../../lib/functions/rpc";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { SectionPrimaryButton } from "../../../components/layout/SectionPrimaryButton";
import { SectionSecondaryButton } from "../../../components/layout/SectionSecondaryButton";

enum EmailSteps {
  email,
  signin,
  signup,
  update,
  reset,
}

export const EmailSignInScreen = ({ navigation, route }: PreambleScreenProps<"EmailSignIn">) => {
  const closeScreen = useCloseScreen();
  const [step, setStep] = useState(
    route.params?.mode === "resetPassword" ? EmailSteps.update : EmailSteps.email
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState<string | null>(null);
  // NOTE: the customer isn't required here, but the app won't swap the application screens until
  // the customer is loading. reading the loading state here allows us to keep the buttons disabled.
  const loadingCustomer = useAuthCustomer((s) => s.isLoading);

  // check whether there is an account for the email and if so determine the provider
  const [checkEmailState, doCheckEmail] = useAsyncFn(async () => {
    setMsg(null);
    try {
      const methods = await firebaseAuth().fetchSignInMethodsForEmail(email);
      console.log("METHODS", methods);
      if (methods && methods.length) {
        if (methods[0] === firebase.auth.EmailAuthProvider.PROVIDER_ID) {
          if (password) {
            // if password field is already set, then also try a sign-in. this
            // happens after a sign-up attempt with an existing email/password
            // combination and then the user changing the email address.
            doSignIn();
          } else {
            setStep(EmailSteps.signin);
          }
        } else {
          navigation.navigate("SignIn", { otherProviderEmail: email, otherProviderId: methods[0] });
        }
      } else {
        setStep(EmailSteps.signup);
      }
    } catch (error) {
      console.error("error checking email address", error);
      setMsg((error as any).message ?? "Error with email address.");
    }
  }, [email, password]);

  // verify password reset code (NOTE: this code is used only by native app)
  useEffect(() => {
    if (route.params?.mode === "resetPassword" && route.params?.oobCode) {
      setStep(EmailSteps.update);
      setEmail("");
      firebaseAuth()
        .verifyPasswordResetCode(route.params?.oobCode)
        .then((email) => {
          setEmail(email);
        })
        .catch((error) => {
          console.error(error);
          setMsg("Outdated password reset link.");
        });
    }
  }, [route.params?.mode, route.params?.oobCode]);

  // sign-in with email/password
  const [signInState, doSignIn] = useAsyncFn(async () => {
    try {
      await firebaseAuth().signInWithEmailAndPassword(email, password);
      setMsg(null);
      // nothing further to do, the MainNavigation will swap to a different stack of screens
    } catch (error) {
      console.error("error trying email sign in", error);
      setMsg((error as any).message ?? "Error signing in.");
    }
  }, [email, password]);

  // sign-up with email/password/name
  const [signUpState, submitSignUp] = useAsyncFn(async () => {
    if (!password || password.length < 6) {
      setMsg("The password must have at least 6 characters.");
    } else {
      try {
        // here the actual user is created and authenticated
        const credential = await firebaseAuth().createUserWithEmailAndPassword(email, password);

        // OLD CODE
        //
        // if (credential.user?.uid) {
        //   const profile = await docWaitUntilExists(VProfiles.doc(credential.user.uid));
        //   if (profile) {
        //     setMsg(null);
        //   } else {
        //     authSignOut();
        //     setMsg("Issue creating account. Please verify the email address.");
        //   }
        // }

        if (credential.user?.uid) {
          setMsg(null);
        } else {
          setMsg("Issue creating account. Please verify the email address.");
        }
        // nothing further to do, the MainNavigation will switch to a different stack of screens
      } catch (error) {
        // this happens when a user account was pre-created by an admin. we then try setting an initial
        // password and then sign-in the customer.
        if ((error as any).code === "auth/email-already-in-use") {
          try {
            const result = await FrontRpc.call("setInitialPassword", { email, password });
            if (result.success) {
              await firebaseAuth().signInWithEmailAndPassword(email, password);
              setMsg(null);
              error = null;
              // nothing further to do, the MainNavigation will swap to a different stack of screens
            }
          } catch (innerError) {
            error = innerError;
          }
        }

        if (error) {
          console.error("error trying sign up", error);
          setMsg((error as any).message ?? "Error signing up.");
        }
      }
    }
  }, [email, password]);

  // update password (NOTE: only the native app uses this code. web used Firebase reset UI.)
  const [updateState, doUpdatePassword] = useAsyncFn(async () => {
    if (route.params?.oobCode) {
      try {
        // 1. update the password
        await firebaseAuth().confirmPasswordReset(route.params?.oobCode, password);
        // 2. immediately sign in the customer
        await firebaseAuth().signInWithEmailAndPassword(email, password);
        // nothing further to do, the MainNavigation will swap to a different stack of screens
      } catch (error) {
        console.error("error trying password update", error);
        setMsg((error as any).message ?? "Error updating password.");
      }
    }
    // signed in?
  }, [route.params?.oobCode, password]);

  // request reset password
  const [resetState, submitResetPassword] = useAsyncFn(async () => {
    try {
      if (Platform.OS === "web") {
        // WEB: password reset done by Firebase UI
        await firebaseAuth().sendPasswordResetEmail(email, {
          url: window.location.href,
        });
      } else {
        // NATIVE: we handle the reset in the app
        await firebaseAuth().sendPasswordResetEmail(email, {
          handleCodeInApp: true,
          url: "https://" + appUniversalDomain(),
        });
      }
      setMsg(null);

      // note: change the preferredProvider, so the customer immediately gets Email offered
      // as option when clicking on the link in the reset email, regardless of what was set
      // before.
      setPreferredProvider(firebase.auth.EmailAuthProvider.PROVIDER_ID);

      setStep(EmailSteps.reset);
    } catch (error) {
      console.error("error trying sign in", error);
      setMsg((error as any).message ?? "Error resetting password.");
    }
  }, [email]);

  function handleEmailChange(text: string) {
    setEmail(text);
    setMsg(null);
  }

  function handlePasswordChange(text: string) {
    setPassword(text);
    setMsg(null);
  }

  function renderInputBlock() {
    if (step === EmailSteps.email) {
      return (
        <>
          <SectionCard divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="email"
              keyboardType="email-address"
              placeholder="Enter Email"
              autoComplete="email"
              autoCorrect={false}
              autoCapitalize={"none"}
              value={email}
              onChangeText={handleEmailChange}
            />
          </SectionCard>
          <SectionError text={msg} />
          <SectionPrimaryButton
            my={3}
            label={"Continue"}
            isDisabled={!email || email.length < 1 || checkEmailState.loading}
            onPress={doCheckEmail}
          />
        </>
      );
    } else if (step === EmailSteps.signin) {
      return (
        <>
          <SectionCard divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="email"
              keyboardType="email-address"
              placeholder="Enter Email"
              autoComplete="email"
              autoCorrect={false}
              autoCapitalize={"none"}
              value={email}
              isDisabled={true}
              onChangeText={handleEmailChange}
            />
            <PasswordField
              placeholder="Enter Password"
              value={password}
              isDisabled={resetState.loading || signInState.loading || loadingCustomer}
              onChangeText={handlePasswordChange}
            />
          </SectionCard>
          <SectionError text={msg} />
          <SectionPrimaryButton
            mt={3}
            mb={1}
            label={"Sign In"}
            isDisabled={
              !email ||
              email.length < 1 ||
              !password ||
              password.length < 1 ||
              resetState.loading ||
              signInState.loading ||
              loadingCustomer
            }
            onPress={doSignIn}
          />
          <SectionSecondaryButton
            my={1}
            label={"Reset Password"}
            onPress={submitResetPassword}
            isDisabled={
              !email ||
              email.length < 1 ||
              resetState.loading ||
              signInState.loading ||
              loadingCustomer
            }
          />
        </>
      );
    } else if (step === EmailSteps.signup) {
      return (
        <>
          <SectionCard divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="email"
              keyboardType="email-address"
              placeholder="Email"
              autoComplete="email"
              autoCorrect={false}
              autoCapitalize={"none"}
              value={email}
              isDisabled={true}
              onChangeText={handleEmailChange}
            />
            <PasswordField
              placeholder="Set a Password"
              value={password}
              isDisabled={signUpState.loading || loadingCustomer}
              onChangeText={handlePasswordChange}
            />
          </SectionCard>
          <SectionError text={msg} />
          <SectionPrimaryButton
            my={3}
            label={"Sign Up"}
            isDisabled={
              !email ||
              email.length < 1 ||
              !password ||
              password.length < 1 ||
              loadingCustomer ||
              signUpState.loading
            }
            onPress={submitSignUp}
          />
        </>
      );
    } else if (step === EmailSteps.update) {
      return (
        <>
          <SectionCard divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="email"
              isDisabled={true}
              keyboardType="email-address"
              placeholder="Email"
              autoComplete="email"
              autoCorrect={false}
              autoCapitalize={"none"}
              value={email}
              onChangeText={handleEmailChange}
            />
            <PasswordField
              placeholder="New Password"
              value={password}
              isDisabled={updateState.loading || loadingCustomer}
              onChangeText={handlePasswordChange}
            />
          </SectionCard>
          <SectionError text={msg} />
          <SectionPrimaryButton
            my={3}
            label={"Update Password"}
            isDisabled={
              !email ||
              email.length < 1 ||
              !password ||
              password.length < 6 ||
              loadingCustomer ||
              updateState.loading
            }
            onPress={doUpdatePassword}
          />
        </>
      );
    } else if (step === EmailSteps.reset) {
      return (
        <Flex flexDirection="column">
          <SectionCard>
            <Text p={4} textAlign="center">
              <Text>An email with a link to reset your password was send to </Text>
              <Text fontWeight="700">{email}</Text>
              <Text>. Please check your Inbox.</Text>
            </Text>
          </SectionCard>
          <SectionPrimaryButton my={3} label={"Back to Sign In"} onPress={() => closeScreen()} />
        </Flex>
      );
    }
  }

  return (
    <Screen name="Email Sign In">
      <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={0}>
        <BannerImage image={require("assets/illustrations/Welcome.svg")} />
        {renderInputBlock()}
        <TCSection />
      </KeyboardAvoidingView>
    </Screen>
  );
};

import React from "react";
import { ImageSourcePropType } from "react-native";
import { AssetView } from "../primitive/AssetView";
import { VFlex } from "./VFlex";
import _omit from "lodash/omit";

const IMAGE_SIZE = 180;

export const BannerImage: React.FunctionComponent<{
  image?: ImageSourcePropType;
  alt?: string;
  mt?: number;
  h?: number;
}> = (props) => {
  const height = props.h ?? IMAGE_SIZE;
  const restProps = _omit(props, ["image", "alt", "mt", "h"]);

  return (
    <VFlex
      position="relative"
      px={4}
      mt={props.mt ?? -2}
      mb={2}
      w="100%"
      bg="#d1eff7"
      borderWidth={1}
      borderColor="coolGray.200"
      borderRadius={8}
      alignItems="center"
      {...restProps}
    >
      <AssetView
        my={2}
        source={props.image ?? require("assets/images/placeholder.png")}
        alt={props.alt ?? "Placeholder"}
        resizeMode="contain"
        w={height}
        h={height}
      />
    </VFlex>
  );
};

import { Divider, HStack } from "native-base";
import React from "react";
import { SectionCard } from "../layout/Sections";
import { Stat } from "./Stats";

export const StatsRow: React.FunctionComponent<{
  title?: string | null;
  borrowed: string;
  returned: string;
  purchased: string;
}> = (props) => {
  return (
    <SectionCard title={props.title}>
      <HStack
        py={2}
        justifyContent="space-evenly"
        w="100%"
        divider={<Divider bg="primaryText" orientation="vertical" h="100%" />}
      >
        <Stat count={props.borrowed} title="Borrowed" />
        <Stat count={props.returned} title="Returned" />
        <Stat count={props.purchased} title="Purchased" />
      </HStack>
    </SectionCard>
  );
};

import { Text } from "native-base";
import React from "react";
import { Linking } from "react-native";
import { SectionTextBox } from "../../../components/layout/SectionTextBox";
import { SectionBox } from "../../../components/layout/Sections";
import { VSpace } from "../../../components/layout/VSpace";
import { Screen } from "../../../components/layout/Screen";
import { BannerImage } from "../../../components/layout/BannerImage";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { SectionPrimaryButton } from "../../../components/layout/SectionPrimaryButton";
import { SectionSecondaryButton } from "../../../components/layout/SectionSecondaryButton";

// ItemInfo shown when the customer is already authenticated.
export const ItemInfoScreen = ({ navigation, route }: FrontAppScreenProps<"ItemInfo">) => {
  const closeScreen = useCloseScreen();

  function handleGoHome() {
    closeScreen();
  }

  function handleGoScan() {
    navigation.replace<any>("Scan");
  }

  function handleLearnMore() {
    Linking.openURL("https://www.recirclable.com");
  }

  return (
    <Screen name="Item Info" backMode="close" onBack={handleGoHome}>
      <BannerImage image={require("assets/illustrations/QR-Code.svg")} />
      <SectionBox>
        <Text my={1} mx={4} textAlign="center" fontSize="lg" fontWeight="600">
          You scanned the QR code on a bowl. What would you like to do?
        </Text>
      </SectionBox>
      <SectionPrimaryButton onPress={handleGoHome} my={3} label="Check my account status" />
      <SectionSecondaryButton onPress={handleGoScan} mt={4} label="Borrow a bowl" />
      <SectionTextBox mt={0}>
        <Text textAlign="center">
          Please scan the LARGE QR CODE displayed at the restaurant checkout to proceed with
          borrowing bowls.
        </Text>
      </SectionTextBox>
      <SectionSecondaryButton mt={4} onPress={handleGoScan} label="Return a bowl" />
      <SectionTextBox mt={0}>
        <Text textAlign="center">
          Please scan the LARGE QR CODE displayed at the Recirclable return bin.
        </Text>
      </SectionTextBox>
      <SectionSecondaryButton
        my={3}
        onPress={handleLearnMore}
        label="Learn more about Recirclable"
      />
    </Screen>
  );
};

import React, { useEffect, useState } from "react";
import { SectionBox } from "../../../components/layout/Sections";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import useAsync from "react-use/lib/useAsync";
import { Text } from "native-base";
import { HFlex } from "../../../components/layout/HFlex";
import { LottiePlayer } from "../../../components/primitive/LottiePlayer";
import { CheckoutLocationSection } from "./CheckoutLocationSection";
import { ScreenTitle } from "../../../components/layout/ScreenTitle";
import { appAnalytics } from "../../../lib/analytics/analytics";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { FrontRpc } from "../../../lib/functions/rpc";
import { VLocations } from "../../../model/VLocationDoc";
import { useAlerts } from "../../../components/modals/AlertProvider";

export const POSCheckOutScreen = ({ navigation, route }: FrontAppScreenProps<"PointPOS">) => {
  const closeScreen = useCloseScreen();
  const alerts = useAlerts();

  const {
    loading: loadingCheckOutResult,
    error: checkOutError,
    value: checkOutResult,
  } = useAsync(() => FrontRpc.call("posCheckout", { pointId: route.params.pointId }));

  const { value: locationLabel } = useAsync(async () => {
    const location = await VLocations.findById(route.params.locationId);
    return location ? ` at ${location.name}` : "";
  });

  const noCheckoutAlertOpts = {
    title: "Borrow Bowls",
    no: "No, I haven't",
    body: `We couldn't find any recent checkout${locationLabel}. Did you place an order and have paid?`,
  };

  useEffect(() => {
    if (!loadingCheckOutResult) {
      if (checkOutError || !checkOutResult?.success) {
        // RPC error -> fallback to manual
        alerts.openAlertYesNo(noCheckoutAlertOpts, handleYesNo);
      } else if (checkOutResult && checkOutResult.success && checkOutResult.transaction) {
        // all good -> show responsive
        switch (checkOutResult.result) {
          case "other":
            console.log("other transaction");
            alerts.openAlertYesNo(
              {
                title: "Borrow Bowls",
                no: "No, I haven't",
                body: `Your recent order${locationLabel} wasn't for any Recirclable bowls. Did you order bowls?`,
              },
              handleYesNo
            );
            break;

          case "done":
            console.log("checkout already done");
            alerts.openAlertOk(
              {
                title: "Borrow Bowls",
                body: `Your recent checkout of bowls${locationLabel} is already recorded.`,
              },
              handleDone
            );
            break;

          case "txn":
            // TODO: server should return the count
            appAnalytics().eventBorrow(0);

            // we got a transactionId
            navigation.replace("ShowReceipt", {
              transactionId: checkOutResult.transaction.id,
              transaction: checkOutResult.transaction,
            });
            break;

          case null:
            console.log("no checkout");
            alerts.openAlertYesNo(noCheckoutAlertOpts, handleYesNo);
            break;
        }
      } else {
        // same invalid response -> fallback to manual
        alerts.openAlertYesNo(noCheckoutAlertOpts, handleYesNo);
      }
    }
  }, [loadingCheckOutResult, checkOutResult]);

  function handleYesNo(yesno?: boolean | null) {
    if (yesno) {
      navigation.replace("PointBorrow", {
        pointId: route.params.pointId,
        locationId: route.params.locationId,
      });
    } else {
      closeScreen();
    }
  }

  function handleDone() {
    closeScreen();
  }

  return (
    <Screen name="Return" backMode="close">
      <ScreenTitle title="Borrow Bowls" />
      <CheckoutLocationSection imageAlt="Checking out at" locationId={route.params.locationId} />
      <SectionBox>
        <HFlex my={2} justifyContent="center">
          <LottiePlayer
            source={require("assets/animations/search-animation.json")}
            speed={0.8}
            height={200}
            width={200}
          />
        </HFlex>
        <Text mx={2} my={2} textAlign="center" fontSize="md" fontWeight="600">
          Please don't close this window. We are searching for your Recirclable order.
        </Text>
      </SectionBox>
    </Screen>
  );
};

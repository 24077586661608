import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Box, useTheme } from "native-base";
import { Platform, Pressable } from "react-native";
import { HomeScreen } from "./HomeScreen";
import { PointScanScreen } from "../checkout/PointScanScreen";
import { ProfileScreen } from "../profile/ProfileScreen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { AccountIcon, HomeIcon, QRScanIcon } from "../../../components/icons/Icons";
import { SafeAreaView } from "react-native-safe-area-context";

export type TabParamList = {
  Overview: undefined;
  Scan: undefined;
  Profile: undefined;
};

const Tab = createBottomTabNavigator<TabParamList>();

export const MainTabs = ({ navigation }: FrontAppScreenProps<"Home">) => {
  const theme = useTheme();

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <Tab.Navigator
        safeAreaInsets={{ bottom: Platform.select({ android: 10, default: 0 }) }}
        screenOptions={{
          tabBarLabelPosition: "below-icon",
          tabBarLabelStyle: { color: "#010A5A", fontSize: 12 },
          tabBarActiveTintColor: "#010A5A",
          tabBarInactiveTintColor: theme.colors.gray[500],
          // tabBarActiveBackgroundColor: "#d1eff7",
          // tabBarInactiveBackgroundColor: "#000080",
        }}
      >
        <Tab.Screen
          name="Overview"
          // HACK. Difficult to fix type. This is caused because the HomeScreen is used both in the Tab and the Stack
          // navigation. This will go away, when we eventually switch to native app only.
          // @ts-ignore
          component={HomeScreen}
          options={{
            headerShown: false,
            title: "Home",
            tabBarIcon: (props) => <HomeIcon paddingTop="1px" size={"xl"} color={props.color} />,
          }}
        />
        <Tab.Screen
          name="Scan"
          component={PointScanScreen}
          options={{
            tabBarButton: (buttonProps) => (
              // IMPORTANT: this must be the react-native Pressable, because the style
              // parameters are passed in from React Navigation, which doesn't know about nativebase.
              <Pressable
                {...buttonProps}
                onPress={() => {
                  navigation.navigate("Scan");
                }}
              />
            ),
            headerShown: false,
            title: "Borrow & Return",
            tabBarIcon: (iconProps) => (
              <Box
                borderRadius={10}
                borderColor="gray.200"
                borderWidth={1}
                shadow="2"
                bgColor="#EAF4F0"
                px={2}
                pt={1.5}
                pb={1}
                mb={8}
              >
                <QRScanIcon size={"3xl"} color={"primaryText"} m={2} />
              </Box>
            ),
          }}
        />
        <Tab.Screen
          name="Profile"
          component={ProfileScreen}
          options={{
            // NOTE: we only need a buttom button, because we want to capture the Pressable
            // for the Game Loop scenario.
            tabBarButton: (buttonProps) => (
              // IMPORTANT: this must be the react-native Pressable, because the style
              // parameters are passed in from React Navigation, which doesn't know about nativebase.
              <Pressable {...buttonProps} />
            ),
            headerShown: false,
            title: "Profile",
            tabBarIcon: (props) => <AccountIcon paddingTop="1px" size={"xl"} color={props.color} />,
          }}
        />
      </Tab.Navigator>
    </SafeAreaView>
  );
};

import React from "react";
import { Box, StatusBar } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";

/**
 * A super simple screen without header and other bells and whistles. And most
 * important outside a Navigator.
 *
 * @param props
 * @returns
 */
export const UndecoratedScreen = (props: { name: string; children?: React.ReactNode }) => {
  return (
    <SafeAreaView edges={["top"]} style={{}}>
      <StatusBar barStyle={"dark-content"} backgroundColor={"white"} translucent={true} />
      <Box
        key={props.name}
        px={[0, 0, 4, 16]}
        w="100%"
        h="100%"
        alignSelf="center"
        bg="screenBackground"
        overflow="hidden"
        _web={{ h: "100vh" }}
      >
        {props.children}
      </Box>
    </SafeAreaView>
  );
};

import { Box, Text } from "native-base";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import { Header } from "../../../components/navigation/Header";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { OrderWebContainer } from "./OrderWebContainer";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { VLocations } from "../../../model/VLocationDoc";
import { getScript } from "./loadInject";
import { useCustomer } from "../../../model/useCustomer";
import useAsync from "react-use/lib/useAsync";
import { useAlerts } from "../../../components/modals/AlertProvider";
import { docGet } from "../../../lib/firestore/fstore";

export const OnlineOrderScreen = ({ navigation, route }: FrontAppScreenProps<"OnlineOrder">) => {
  const closeScreen = useCloseScreen();
  const { profile } = useCustomer();
  const webRef = useRef<WebView>(null);
  const [canGoBack, setCanGoBack] = useState(false);
  const alerts = useAlerts();

  useEffect(() => {
    alerts.openAlertOk({
      title: "Select Recirclable",
      // text: "Please be sure to select the Recirclable modifier on each eligible item in your order and use the email address and phone number provided with your Recirclable account, to ensure they are packed in reusables.",
      body: () => {
        return (
          <Text>
            Please be sure to select the{" "}
            <Text fontFamily={"NotoSans-Bold"}>Recirclable modifier</Text> on each eligible item in
            your order and use the{" "}
            <Text fontFamily={"NotoSans-Bold"}>email address or phone number</Text> provided with
            your Recirclable account, to ensure they are packed in reusables.
          </Text>
        );
      },
      ok: "Continue",
    });
  }, []);

  // load all the online configuration, e.g. the URL and injection script
  const onlineConfigState = useAsync(async () => {
    // customer must have "orderCode"
    if (profile.orderCode) {
      if (route.params.locationId) {
        // location must be set up for online ordering
        const location = await docGet(VLocations.doc(VLocations.doc(route.params.locationId)));
        if (location && location.onlineOrderUrl && location.onlineOrder) {
          // load the script and inject custom variables
          const script = await getScript(location.onlineOrder, {
            customerCode: profile.orderCode,
            orderRootUrl: location.onlineOrderUrl,
          });
          if (script) {
            if (!script.includes("<html ")) {
              return {
                url: location.onlineOrderUrl,
                script,
              };
            }
            console.warn("got html instead of JavaScript", script.substring(0, 30));
          } else {
            console.warn("couldn't load script for", location.onlineOrder);
          }
        } else {
          console.warn("location not setup for online ordering", route.params.locationId);
        }
      } else {
        console.warn("missing location id", route.params.locationId);
      }
    } else {
      console.warn("customer not setup for online ordering", profile.id);
    }

    // some precondition not ok -> close screen
    closeScreen();
  }, [profile.id]);

  // a back press either navigates the browser back, or if not possible brings the
  // user back into the main app.
  const handleBack = useCallback(() => {
    if (canGoBack) {
      webRef.current?.goBack();
    } else {
      closeScreen();
    }
  }, [canGoBack]);

  // a long press brings the user back in the main app
  const handleClose = useCallback(() => {
    closeScreen();
  }, []);

  // we watch the navigation and determine whether "back" is possible
  const handleCanGoBack = useCallback((canGoBack: boolean) => {
    console.log("🍏 WEBVIEW can go back:", canGoBack);
    setCanGoBack(canGoBack);
  }, []);

  return (
    <SafeAreaView
      edges={["top", "bottom"]}
      style={{
        backgroundColor: "white",
      }}
    >
      <Box
        w="100%"
        h="100%"
        alignSelf="center"
        bg="screenBackground"
        overflow="hidden"
        _web={{ h: "100vh" }}
      >
        <Header
          title={"Recirclable"}
          backMode="both"
          onBack={handleBack}
          onLongBack={handleClose}
          onClose={handleClose}
        />
        {onlineConfigState.value ? (
          <OrderWebContainer
            ref={webRef}
            url={onlineConfigState.value.url}
            title="Order Online"
            injectScript={onlineConfigState.value.script}
            onCanGoBack={handleCanGoBack}
          />
        ) : null}
      </Box>
    </SafeAreaView>
  );
};

import React, { useEffect, useState } from "react";
import { useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { Box, Spinner, Text } from "native-base";
import { FrontRpc } from "../../../../lib/functions/rpc";
import { HFlex } from "../../../../components/layout/HFlex";
import { HSpace } from "../../../../components/layout/HSpace";
import { VFlex } from "../../../../components/layout/VFlex";

export const PaymentButtonSection = (props: {
  isDisabled: boolean; // external disabled
  isLoading: boolean; // additional external loading
  itemPrice: number;
  intentSecret: string;
  onProcessing: (state: boolean) => void;
  onDone: () => void;
}) => {
  const stripe = useStripe();
  const [didSubmit, setDidSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [payEvent, setPayEvent] = useState<PaymentRequestPaymentMethodEvent | null>(null);
  const [message, setMessage] = useState<string | null | undefined>(null);

  // create the payment request (if browser environment supports it)
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        displayItems: [
          {
            label: "Charge for every bowl not returned",
            amount: props.itemPrice,
          },
        ],
        total: {
          label: "Recirclable LLC (charge for every bowl not returned)",
          amount: 0,
          pending: true,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ["link", "browserCard"],
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          pr.on("paymentmethod", async (pe) => {
            setPayEvent(pe);
            setProcessing(true);
            setDidSubmit(true);
            props.onProcessing(true);
          });
          pr.on("cancel", () => {
            setProcessing(false);
            props.onProcessing(false);
          });
        } else {
          console.info("payment request for button not available.");
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    // note: the effect can run, when all pre-conditions are met (stripe, secret)
    // and when the user has pressed submit. if the user presses submit, before the
    // pre-conditions are met, then the app will just show the busy spinner and start
    // automically with the processing when the pre-conditions are meet.
    if (payEvent && stripe) {
      stripe
        .confirmCardSetup(props.intentSecret!, {
          payment_method: payEvent.paymentMethod.id,
        })
        .then(({ setupIntent, error }) => {
          if (!error) {
            FrontRpc.call("stripeFinish", { setupIntentId: setupIntent.id })
              .then(() => {
                payEvent.complete("success");
                setProcessing(false);
                props.onDone();
              })
              .catch((error) => {
                payEvent.complete("fail");
                console.error(error);
                setMessage("There was an issue processing the card.");
                setPayEvent(null);
                setProcessing(false);
                props.onProcessing(false);
              });
          } else {
            payEvent.complete("fail");
            console.error(error.message);
            setMessage(error.message);
            setPayEvent(null);
            setProcessing(false);
            props.onProcessing(false);
          }
        })
        .catch((error) => {
          payEvent.complete("fail");
          console.error(error);
          setMessage("There was an issue confirming the card.");
          setPayEvent(null);
          setProcessing(false);
          props.onProcessing(false);
        });
    }
  }, [stripe, payEvent]);

  if (paymentRequest) {
    return (
      <VFlex w="100%">
        <Box position="relative">
          <PaymentRequestButtonElement
            onClick={(event) => {
              if (props.isDisabled) event.preventDefault();
            }}
            options={{ paymentRequest }}
          />
          {props.isDisabled || processing || props.isLoading ? (
            <HFlex
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgColor="rgba(255, 255, 255, 0.4)"
              borderRadius={8}
              justifyContent={"center"}
            >
              <HSpace w="90px" />
              {(processing || (didSubmit && props.isLoading)) && (
                <Spinner size="sm" color="white" />
              )}
            </HFlex>
          ) : null}
        </Box>
        {message ? (
          <Text py={2} color="errorMessage">
            {message}
          </Text>
        ) : null}
        <Text py={4} textAlign="center" fontSize="sm" color="gray.600">
          - or enter card details -
        </Text>
      </VFlex>
    );
  }
  return null;
};

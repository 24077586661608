import React from "react";
import { Box, Text } from "native-base";
import { VFlex } from "../layout/VFlex";
import { HFlex } from "../layout/HFlex";

const SIZE = "53px";
export const LargeStat: React.FunctionComponent<{ count: string; title: string }> = (props) => {
  const lines = props.title.split(" ");

  return (
    <HFlex p={2} justifyContent="flex-start">
      <Box
        bg="primaryText"
        borderRadius="md"
        w={SIZE}
        h={SIZE}
        justifyContent="center"
        alignItems="center"
      >
        <Text color="white" fontSize="3xl" fontWeight="700">
          {props.count}
        </Text>
      </Box>
      <VFlex ml={2}>
        <Text color="primaryText" fontSize="lg" fontWeight="700">
          {lines[0]}
        </Text>
        <Text color="primaryText" fontSize="lg" fontWeight="700">
          {lines[1] ?? ""}
        </Text>
      </VFlex>
    </HFlex>
  );
};

export const Stat: React.FunctionComponent<{ count: string; title: string }> = (props) => {
  return (
    <VFlex alignItems="center">
      <Text color="primaryText" fontSize="2xl" fontWeight="700">
        {props.count}
      </Text>
      <Text mt={1} color="primaryText" fontSize="sm" fontWeight="700">
        {props.title}
      </Text>
    </VFlex>
  );
};

import React, { useEffect } from "react";
import { Center, Text } from "native-base";
import useAsync from "react-use/lib/useAsync";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { ActivitySpinner } from "../../../components/primitive/ActivitySpinner";
import { FrontRpc } from "../../../lib/functions/rpc";

export const LinkTransactionScreen = ({
  navigation,
  route,
}: FrontAppScreenProps<"LinkTransaction">) => {
  const { prefix, txnId } = route.params;
  const stateRegister = useAsync(async () => {
    if (prefix && txnId) {
      // NOTE: we only take 8 characters from the transaction id in case extra characters where added.
      //       during development there was a situation where extra characters where added.
      return FrontRpc.call("linkTransaction", { prefix, transactionCode: txnId.substring(0, 8) });
    }
    return null;
  });

  useEffect(() => {
    if (!stateRegister.loading) {
      if (stateRegister.value?.transactionId) {
        navigation.replace("ShowReceipt", { transactionId: stateRegister.value.transactionId });
      } else {
        navigation.navigate("Home");
      }
    }
  }, [stateRegister.loading]);

  return (
    <Screen name="Register Transaction">
      <Center w="100%" h="100%" alignItems="center">
        <Text px={3} pt={2} pb={6} color="primaryText" fontSize="md" fontWeight="700">
          Registering your recent order.
        </Text>
        <ActivitySpinner size="lg" />
      </Center>
    </Screen>
  );
};

import _omit from "lodash/omit";
import _pick from "lodash/pick";
import { SectionBox } from "./Sections";
import { PrimaryButton } from "../primitive/PrimaryButton";
import { IButtonProps } from "native-base";

export function SectionPrimaryButton(props: { label: string } & IButtonProps) {
  const sectionProps = _pick(props, ["mx", "my", "mt", "mb", "ml", "mr"]);
  const buttonProps = _omit(props, ["mx", "my", "mt", "mb", "ml", "mr"]);
  return (
    <SectionBox {...sectionProps}>
      <PrimaryButton {...buttonProps} />
    </SectionBox>
  );
}

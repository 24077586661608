import React, { useEffect } from "react";
import { useDisclose } from "native-base";
import { SectionCard } from "../../../components/layout/Sections";
import { HelloSection } from "./HelloSection";
import { useDate } from "../../../hooks/useDate";
import { useCustomer } from "../../../model/useCustomer";
import { isProduction } from "../../../lib/firebase/ifEnv";
import { useTestState } from "../../../hooks/useTestState";
import { BackOfficeHeaderButtons } from "../../../components/navigation/BackOfficeHeaderButtons";
import DevActionSheet from "./DevActionSheet";
import { Screen } from "../../../components/layout/Screen";
import { PartnerSection } from "./PartnerSection";
import { CountSection } from "../../../components/primitive/CountSection";
import { hasPendingLink, popPendingLink } from "../../../components/navigation/pendingLink";
import { useLinkTo } from "@react-navigation/native";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { friendlyFuture } from "../../../util/format";
import { VTransactions } from "../../../model/VTransactionDoc";
import { InteractionManager } from "react-native";
import { useMailComposer } from "../../../hooks/useMailComposer";
import { VerifyPhoneToast } from "../profile/VerifyPhoneToast";
import { ONE_HOUR } from "../../../util/constants";
import { docQuery } from "../../../lib/firestore/fstore";
import { useRemoteFlags } from "../../../lib/remote_flags/useRemoteFlags";
import { useAlerts } from "../../../components/modals/AlertProvider";
import { ModalReminderBody } from "./ModalReminderBody";
import useEffectOnce from "react-use/lib/useEffectOnce";
import { useLocalUserState } from "../../../hooks/useLocalUserState";
import { ModalWelcomeBody } from "./ModalWelcomeBody";
import { SectionItemChevronButton } from "../../../components/layout/SectionItemChevronButton";

export const HomeScreen = ({ navigation, route }: FrontAppScreenProps<"Home">) => {
  const alerts = useAlerts();
  const { didShowPaymentSetup: isSignUp } = useLocalUserState();
  const { didVisitPoint, didShowWelcome } = useLocalUserState();
  const { showedWelcome } = useLocalUserState();
  const composeMail = useMailComposer();
  const linkTo = useLinkTo();
  const { sampleReturnInfo, sampleCompleteModal } = useTestState();
  const dateState = useDate();
  const { balance, profile } = useCustomer();
  const featureOnlineOrder = useRemoteFlags((f) => f.onlineOrder);
  const devSheetState = useDisclose(false);

  // calculate an expiration summary
  let summary = balance.getExpirationSummary(dateState.now());
  summary.today += summary.overdue;
  if (!isProduction()) {
    if (sampleReturnInfo == 1) {
      summary = {
        sum: 15,
        overdue: 0,
        today: 1,
        tomorrow: 2,
        days2: 3,
        week: 4,
        rest: 5,
      };
    } else if (sampleReturnInfo == 2) {
      summary = {
        sum: 9,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        days2: 0,
        week: 4,
        rest: 5,
      };
    }
  }

  // handle pending links
  useEffect(() => {
    const link = popPendingLink();
    if (link) {
      console.log("HAS PENDING LINK");
      InteractionManager.runAfterInteractions(() => {
        try {
          console.log("HANDLING PENDING LINK");
          linkTo(link);
        } catch (error) {
          console.error("LINK ERROR", link);
        }
      });
    }
  }, []);

  // show reminder
  useEffect(() => {
    if (!hasPendingLink()) {
      if (summary.today > 0 || summary.tomorrow > 0 || summary.days2 > 0 || summary.week > 0) {
        const imageSource =
          summary.today > 0
            ? require("assets/illustrations/Appointment.svg")
            : require("assets/illustrations/Time.svg");
        alerts.openAlertOk({
          body: () => {
            return <ModalReminderBody summary={summary} />;
          },
          imageSource,
          ok: "Continue",
        });
      }
    }
  }, [summary.today, summary.tomorrow, summary.days2, summary.week]);

  // show welcome modal
  useEffectOnce(() => {
    if (!hasPendingLink()) {
      if (isSignUp && !didVisitPoint && !didShowWelcome) {
        showedWelcome();
        alerts.openAlertOk({
          body: () => {
            return <ModalWelcomeBody />;
          },
          imageSource: require("assets/illustrations/Friends-Wave.svg"),
          ok: "Continue",
        });
      }
    }
  });

  function handleDetailsPress() {
    navigation.push("Activity");
  }

  function handleSuggestPress() {
    composeMail({
      to: "hello@recirclable.com",
      subject: "Restaurant Suggestion",
      body:
        "Do you have a favorite restaurant that isn't yet using reusable " +
        "containers but is interested in exploring this option? " +
        "Please tell us about them, and help to shift habits and " +
        "mindset from recyclable to Recirclable! Thank you!",
    });
  }

  function handleShowMostRecent() {
    docQuery(
      VTransactions.query()
        .where("accountId", "==", balance.id)
        .where("transactionAt", ">=", new Date(Date.now() - ONE_HOUR))
        .orderBy("transactionAt", "desc")
        .limit(1)
    ).then((transactions) => {
      if (transactions.length > 0 && transactions[0].type === "borrow") {
        navigation.push("ShowReceipt", { transactionId: transactions[0].id });
      }
    });
  }

  const handleOnlineOrder = (locationId: string) => {
    navigation.navigate("OnlineOrder", { locationId });
  };

  const nextAt = balance.getNextExpiresAt();
  const nextDueMessage = nextAt ? `due by ${friendlyFuture(nextAt)}` : undefined;

  return (
    <Screen
      name="Home"
      backMode="none"
      right={<BackOfficeHeaderButtons />}
      overlay={() => (
        <VerifyPhoneToast profile={profile} onPress={() => navigation.push("ProfileChangePhone")} />
      )}
    >
      <HelloSection onLongPress={devSheetState.onOpen} />
      <CountSection
        count={balance?.outstandingSum?.toFixed() ?? "0"}
        title="Currently Borrowed"
        message={nextDueMessage}
        onPress={handleShowMostRecent}
      />
      <SectionCard>
        <SectionItemChevronButton label="Show Activity Details" onPress={handleDetailsPress} />
      </SectionCard>
      <PartnerSection
        suggestTile={true}
        onSuggest={handleSuggestPress}
        onOnlineOrder={featureOnlineOrder ? handleOnlineOrder : undefined}
      />
      <DevActionSheet {...devSheetState} />
    </Screen>
  );
};

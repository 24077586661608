import { Link, Text } from "native-base";
import React from "react";
import { VFlex } from "../../../components/layout/VFlex";

export const ModalWelcomeBody: React.FunctionComponent<{}> = (props) => {
  return (
    <VFlex alignItems="center">
      <Text my={3} fontSize="lg" fontWeight="700" textAlign="center">
        Welcome!
      </Text>
      <Text mb={5} fontSize="md" fontWeight="400" textAlign="center">
        <Text>Thank you for signing up with Recirclable. Visit one of our </Text>
        <Link
          href="https://www.recirclable.com/restaurants"
          isUnderlined
          _text={{ fontSize: "md", fontWeight: 700 }}
        >
          participating restaurants
        </Link>
        <Text>
          {" "}
          and let them know you want your takeout in a Recirclable container. Scan the QR code at
          checkout, follow the instructions and enjoy your food!
        </Text>
      </Text>
    </VFlex>
  );
};

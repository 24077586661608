import { Box, Divider, Image, Text } from "native-base";
import React from "react";
import { ImageSourcePropType } from "react-native";
import { AssetView } from "../../../components/primitive/AssetView";
import { SectionCard } from "../../../components/layout/Sections";
import useAsync from "react-use/lib/useAsync";
import { expandProtoRelUrl } from "../../../util/url";
import { VLocations } from "../../../model/VLocationDoc";
import { VFlex } from "../../../components/layout/VFlex";
import { HFlex } from "../../../components/layout/HFlex";

const PARTNER_IMAGE_SIZE = "80px";

export const CheckoutLocationSection: React.FunctionComponent<{
  locationId: string;
  imageSource?: ImageSourcePropType;
  imageAlt: string;
}> = (props) => {
  const { loading: loading, value: location } = useAsync(() => {
    return VLocations.findById(props.locationId);
  });

  const locationName = location?.name ?? " ";
  const addressLine1 = `${location?.address?.address1 ?? " "}`;
  const addressLine2 = `${location?.address?.city ?? " "} ${location?.address?.state ?? ""} ${
    location?.address?.postalCode ?? ""
  }`;

  const locationImageSource = loading
    ? null
    : location?.image
    ? { uri: expandProtoRelUrl(location.image) }
    : require("assets/illustrations/partner-placeholder-gray.png");

  return (
    <SectionCard p={2} pt={0} alignItems="center" shadow={99}>
      {props.imageSource ? (
        <AssetView
          source={props.imageSource}
          alt={props.imageAlt}
          resizeMode="contain"
          w={160}
          h={160}
        />
      ) : (
        <Text my={2} textAlign="center" fontWeight="400" fontSize="sm">
          {props.imageAlt}
        </Text>
      )}
      <Divider size="1px" w="100%" />
      <HFlex mt={2} w="100%">
        {locationImageSource ? (
          <Image
            source={locationImageSource}
            alt={locationName}
            resizeMode="contain"
            w={PARTNER_IMAGE_SIZE}
            h={PARTNER_IMAGE_SIZE}
          />
        ) : (
          <Box backgroundColor="gray.100" w={PARTNER_IMAGE_SIZE} h={PARTNER_IMAGE_SIZE}></Box>
        )}
        <VFlex py={1} px={3} justifyContent="flex-end">
          <Text lineHeight="2xl" fontWeight={700}>
            {locationName}
          </Text>
          <Text fontWeight={400} fontSize="sm">
            {addressLine1}
          </Text>
          <Text fontWeight={400} fontSize="sm">
            {addressLine2}
          </Text>
        </VFlex>
      </HFlex>
    </SectionCard>
  );
};
